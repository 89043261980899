import React, { useState, useContext, useEffect } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CForm,
  CFormText,
  CLabel,
  CContainer,
  CCol,
  CRow,
  CCardHeader,
  CBadge,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarkerAlt,
  faDollarSign,
  faPencilAlt,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../hooks/AuthContext";
import moment from "moment";
import { CartContext } from "../hooks/CartContext";
import axios from "axios";
import AdditionalUserInput from "./AdditionalUserInput";

export default function EventCard(props) {
  const [event, setEvent] = useState(props.event);
  const [link, setLink] = useState("/events/detail/" + props.event._id);
  const { user } = useContext(AuthContext);
  const { cart } = useContext(CartContext);
  const cartContext = useContext(CartContext);
  const [isInCart, setIsInCart] = useState(false);
  const [userHasBooked, setUserHasBooked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //Additional User Input Modal
  const [showAdditionalUserInputModal, setShowAdditionalUserInputModal] =
    useState(false);
  const [additionaUserInputModalMessage, setAdditionaUserInputModalMessage] =
    useState(false);
  const [additionalUserInputModalMode, setAdditionalUserInputModalMode] =
    useState("Add To Cart");
  const [additionalUserInputModalTitle, setAdditionalUserInputModalTitle] =
    useState("");
  const [additionalUserInputModalLink, setAdditionalUserInputModalLink] =
    useState("");
  const [additionalUserInputModalFields, setAdditionalUserInputModalFields] =
    useState([]);
  const [modalMode, setModalMode] = useState("Info");

  function additionalInputListener(data) {
    console.log("Got data: " + Object.keys(data));
    cartContext.addToCart({
      item: event.eventName,
      description: event.startDate + " - " + event.endDate,
      price: event.price,
      event: event,
      additionalFields: data,
    });
  }
  function hideAdditionalUserInputModal(value) {
    setShowAdditionalUserInputModal(false);
    switch (value) {
      case "Confirm and Add To Cart":
        //Add to cart
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setIsLoading(true);
    cart.map((cartItem) => {
      if (cartItem.hasOwnProperty("event") && cartItem.event._id == event._id) {
        setIsInCart(true);
      }
    });
    props.userBookings.map((booking) => {
      booking.events.map((event_) => {
        if (event_._id == event._id) {
          setUserHasBooked(true);
        }
      });
    });
    if (
      event.hasOwnProperty("eventCategories") &&
      event.eventCategories.length > 0
    ) {
      setAdditionalUserInputModalFields(event.eventCategories[0].fields);
    }
    axios
      .get(process.env.REACT_APP_API_URL + "/events/" + event._id + "/bookings")
      .then((res) => {
        let event_ = event;
        event_.bookings = res.data;
        setEvent(event_);
        setIsLoading(false);
      });
  }, []);

  const displayAdditionalInputModal = (fields) => {
    setShowAdditionalUserInputModal(true);
  };

  function addToCart() {
    if (
      event.hasOwnProperty("eventCategories") &&
      event.eventCategories.length > 0 &&
      event.eventCategories[0].fields.length > 0
    ) {
      console.log(event.eventCategories[0].fields);
      displayAdditionalInputModal(event.eventCategories[0].fields);
    } else {
      cartContext.addToCart({
        item: event.eventName,
        description: event.startDate + " - " + event.endDate,
        price: event.price,
        event: event,
      });
      setIsInCart(true);
    }
  }

  function removeFromCart() {
    cartContext.removeFromCart({
      item: event.eventName,
      description: event.startDate + " - " + event.endDate,
      price: event.price,
      event: event,
    });
    setIsInCart(false);
  }

  function CButtonGroup_() {
    if (user && user.accType != "Admin") {
      if (!props.userMembership && event.eventAccess == "Member") {
        return <>Please apply for a membership to book this event</>;
      } else if (isInCart) {
        return (
          !isLoading && (
            <CButtonGroup className="mt-3">
              <CButton className="btn-danger" onClick={removeFromCart}>
                Remove From Cart
              </CButton>
            </CButtonGroup>
          )
        );
      } else if (userHasBooked) {
        return (
          !isLoading && (
            <CButtonGroup className="mt-3">
              <CButton className="btn-danger" disabled>
                Already Booked
              </CButton>
            </CButtonGroup>
          )
        );
      } else if (event.bookings) {
        if (event.bookings.length == props.event.capacity) {
          return (
            !isLoading && (
              <CButtonGroup className="mt-3">
                <CButton className="btn-danger" disabled>
                  Event Full
                </CButton>
              </CButtonGroup>
            )
          );
        } else if (
          event.isCutOffDateEnabled != undefined &&
          event.isCutOffDateEnabled == "Enabled"
        ) {
          var currentDate = new Date();
          var cutOffDate = new Date(event.cutOffDate);
          if (currentDate.getTime() < cutOffDate.getTime()) {
            return (
              !isLoading && (
                <CButtonGroup className="mt-3">
                  <CButton className="btn-custom" onClick={addToCart}>
                    Add To Cart
                  </CButton>
                </CButtonGroup>
              )
            );
          } else {
            return (
              !isLoading && <div>Sorry, this event's deadline has passed</div>
            );
          }
        } else {
          return (
            !isLoading && (
              <CButtonGroup className="mt-3">
                <CButton className="btn-custom" onClick={addToCart}>
                  Add To Cart
                </CButton>
              </CButtonGroup>
            )
          );
        }
      } else {
        if (
          event.isCutOffDateEnabled != undefined &&
          event.isCutOffDateEnabled == "Enabled"
        ) {
          var currentDate = new Date();
          var cutOffDate = new Date(event.cutOffDate);
          if (currentDate.getTime() < cutOffDate.getTime()) {
            return (
              !isLoading && (
                <CButtonGroup className="mt-3">
                  <CButton className="btn-custom" onClick={addToCart}>
                    Add To Cart
                  </CButton>
                </CButtonGroup>
              )
            );
          } else {
            return (
              !isLoading && <div>Sorry, this event's deadline has passed</div>
            );
          }
        }
        return (
          !isLoading && (
            <CButtonGroup className="mt-3">
              <CButton className="btn-custom" onClick={addToCart}>
                Add To Cart
              </CButton>
            </CButtonGroup>
          )
        );
      }
    }
    return !isLoading && null;
  }

  if (user && user.accType == "Admin") {
    return (
      <>
        <CCard className="event-card">
          <CCardHeader className="event-card-header">
            <div className="detail-row">
              <div className="inner">
                <NavLink to={link}>
                  <h6>{event.eventName}</h6>
                </NavLink>
              </div>
              <div className="inner">
                <CBadge className="badge badge-custom badge-sisa mr-2">
                  {event.eventType}
                </CBadge>
                <CButton
                  className="event-edit-btn"
                  href={"/admin/events/edit/" + event._id}
                >
                  <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                </CButton>
              </div>
            </div>
            <div className="detail-row">
              Availability{" "}
              {event.bookings != undefined
                ? event.capacity - event.bookings.length
                : 0}
              /{event.capacity}
            </div>
          </CCardHeader>
          <CCardBody>
            <CContainer fluid>
              <CRow className="mb-3">
                <FontAwesomeIcon icon={faHourglass} className="mr-2" />
                Registration Cut Off Date:{" "}
                {event.isCutOffDateEnabled != undefined &&
                event.isCutOffDateEnabled == "Enabled" &&
                event.cutOffDate != undefined
                  ? moment(event.cutOffDate).format("DD/MM/YYYY hh:mm:ss a")
                  : "No Cut Off Date"}
              </CRow>
              <CRow className="mb-3">
                <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                {moment(event.startDate).format("DD/MM/YYYY hh:mm:ss a")} -{" "}
                {moment(event.endDate).format("DD/MM/YYYY hh:mm:ss a")}
              </CRow>
              <CRow className="mb-3">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                {event.address}
              </CRow>
              <CRow className="mb-3">
                <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                {event.price}
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </>
    );
  } else {
    return (
      !isLoading && (
        <>
          <AdditionalUserInput
            show={showAdditionalUserInputModal}
            handleClose={() => hideAdditionalUserInputModal(modalMode)}
            fields={additionalUserInputModalFields}
            mode={additionalUserInputModalMode}
            title={"Additional Input Required"}
            event={event}
            submitListener={additionalInputListener}
          />
          <CCard className="event-card">
            <CCardHeader className="event-card-header">
              <div className="detail-row">
                <div className="inner">
                  <NavLink to={link}>
                    <h6>{event.eventName}</h6>
                  </NavLink>
                </div>
                <div className="inner">
                  <CBadge className="badge badge-custom badge-sisa mr-2">
                    {event.eventType}
                  </CBadge>
                </div>
              </div>
              <div className="detail-row">
                Availability{" "}
                {event.bookings != undefined
                  ? event.capacity - event.bookings.length
                  : 0}
                /{event.capacity}
              </div>
            </CCardHeader>
            <CCardBody>
              <CContainer fluid>
                <CRow className="mb-3">
                  <FontAwesomeIcon icon={faHourglass} className="mr-2" />
                  Registration Cut Off Date:{" "}
                  {event.isCutOffDateEnabled != undefined &&
                  event.isCutOffDateEnabled == "Enabled" &&
                  event.cutOffDate != undefined
                    ? moment(event.cutOffDate).format("DD/MM/YYYY hh:mm:ss a")
                    : "No Cut Off Date"}
                </CRow>
                <CRow className="mb-3">
                  <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                  {moment(event.startDate).format(
                    "DD/MM/YYYY hh:mm:ss a"
                  )} - {moment(event.endDate).format("DD/MM/YYYY hh:mm:ss a")}
                </CRow>
                <CRow className="mb-3">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                  {event.address}
                </CRow>
                <CRow className="mb-3">
                  <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                  {event.price}
                </CRow>
              </CContainer>
              <CButtonGroup_></CButtonGroup_>
            </CCardBody>
          </CCard>
        </>
      )
    );
  }
}
