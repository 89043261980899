import "../assets/css/modal.css";
import {
  CModal,
  CButton,
  CModalTitle,
  CModalBody,
  CModalHeader,
  CModalFooter,
} from "@coreui/react";

const Modal = ({ handleClose, show, link, title, message, mode }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  if (mode == "Info") {
    return (
      <CModal visible={show} onClose={() => handleClose()}>
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{message}</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => handleClose("Close")}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    );
  } else if (mode == "Error") {
    return (
      <CModal visible={show} onClose={() => handleClose()}>
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{message}</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => handleClose("Close")}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    );
  } else if (mode == "Link") {
    return (
      <CModal visible={show} onClose={() => handleClose()}>
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {message} <a href={link}>{link}</a>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => handleClose("Close")}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    );
  } else {
    return (
      <CModal visible={show} onClose={() => handleClose()}>
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{message}</CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={() => handleClose("Yes")}>
            Yes
          </CButton>
          <CButton color="secondary" onClick={() => handleClose("No")}>
            No
          </CButton>
        </CModalFooter>
      </CModal>
    );
  }
};

export default Modal;
