import React, { useState, useContext, useEffect } from "react";
import RenewMembership from "./RenewMembership";
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CCardHeader,
  CButton,
} from "@coreui/react";
import CreateNewUserForm from "../components/CreateNewUserForm";
import Cart from "./Cart";
import { CartContext } from "../hooks/CartContext";
import { useHistory, useLocation } from "react-router";
import axios from "axios";

export default function PublicSignUp(props) {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  const cartContext = useContext(CartContext);
  const history = useHistory();

  const updateMembershipDetails = (details) => {
    console.log(details);
    setMembershipDetails(details);
    localStorage.removeItem("membershipDetails");
    localStorage.setItem(
      "membershipDetails",
      JSON.stringify({
        details,
      })
    );
  };
  const updateUserDetails = (details) => {
    console.log(details);
    setUserDetails(details);
    localStorage.removeItem("userDetails");
    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        details,
      })
    );
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let storedUserDetails, storedMembershipDetails;

  useEffect(() => {
    setIsLoading(true);
    storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    storedMembershipDetails = JSON.parse(
      localStorage.getItem("membershipDetails")
    );

    console.log(storedUserDetails);
    console.log(storedMembershipDetails);

    if (storedUserDetails != null && storedUserDetails.userDetails != null) {
      setUserDetails(storedUserDetails.userDetails);
    }

    if (
      storedMembershipDetails != null &&
      storedMembershipDetails.membershipDetails != null
    ) {
      setMembershipDetails(storedMembershipDetails.membershipDetails);
    }

    /*if (query.get("reference") != null && query.get("status") === "completed") {
      delete storedUserDetails.confirmPassword;
      let editedDetails = storedUserDetails.details;
      editedDetails.firstName =
        storedMembershipDetails.details.membershipDetails.firstName;
      editedDetails.lastName =
        storedMembershipDetails.details.membershipDetails.lastName;
      editedDetails.isActive = true;
      axios
        .post(process.env.REACT_APP_API_URL + "/register", editedDetails)
        .then((res) => {
          axios.defaults.headers.common["Authorization"] = res.data.token;
          processBooking(
            res.data._id,
            query.get("reference"),
            "PayNow",
            "Hitpay",
            storedMembershipDetails.details.membershipDetails
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }*/
    setIsLoading(false);
  }, []);

  function processBooking(
    userId,
    paymentRef,
    paymentMethod,
    paymentProvider,
    membershipDetails
  ) {
    let events = [];

    let item = cartContext.cart[0];
    if (item.hasOwnProperty("membershipId")) {
      let object = {
        membershipId: item.membershipId,
        membershipDetails: membershipDetails,
        userId: userId,
        paymentRef: paymentRef,
        paymentMethod: "PayNow",
        paymentProvider: paymentProvider,
      };
      console.log("Checking out...");
      axios
        .post(process.env.REACT_APP_API_URL + "/checkout", object)
        .then((res) => {
          if (res.status === 200) {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("membershipDetails");
            cartContext.emptyCart();
            history.replace("/login");
          }
        })
        .catch((err) => {});
    }
  }

  const StepOne = () => {
    return (
      <>
        <CCardHeader>
          You are required to sign up for a SISA Membership in order to create
          an account
        </CCardHeader>
        <RenewMembership
          updateMembershipDetails={updateMembershipDetails}
          membershipDetails={membershipDetails}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      </>
    );
  };

  const StepTwo = () => {
    return (
      <>
        <CCardHeader>Create An Account</CCardHeader>
        <CreateNewUserForm
          updateUserDetails={updateUserDetails}
          userDetails={userDetails}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      </>
    );
  };

  const StepThree = () => {
    return (
      <>
        <CCardHeader>
          Please make payment before your membership and account is processed
        </CCardHeader>
        <Cart prevStep={prevStep} nextStep={nextStep} />
      </>
    );
  };

  const addToCart = () => {
    let object = {};
    object.membershipId = "61985aac7bde4271bb9371cd";
    object.item = "SISA Annual Individual Membership";
    object.price = 30.0;
    console.log(object);
    cartContext.addToCart(object);
    console.log("Adding to cart called");
  };

  const nextStep = () => {
    setIsLoading(true);
    setStep(step + 1);
    if (step == 2) {
      addToCart();
    }
    setIsLoading(false);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const CurrentStep = () => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo prevStep={prevStep} nextStep={nextStep} />;
      case 3:
        return <StepThree prevStep={prevStep} nextStep={nextStep} />;
      default:
        return <StepOne prevStep={prevStep} nextStep={nextStep} />;
    }
  };

  return (
    !isLoading && (
      <div className="d-flex bg-light border">
        <CCard style={{ width: "100%" }}>
          <CCardBody>
            <CContainer>
              <CCol>
                <CurrentStep />
              </CCol>
            </CContainer>
          </CCardBody>
        </CCard>
      </div>
    )
  );
}
