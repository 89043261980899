import React, { useContext, useState, useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { AuthContext } from "../hooks/AuthContext";
import { CartContext } from "../hooks/CartContext";
//import Loading from "./../components/Loading";
import UserDashboard from "../pages/UserDashboard";
import Calendar from "../pages/Calendar";
import Bookings from "../pages/Bookings";

import Events from "../pages/Events";
import EventDetail from "../pages/EventDetail";

import Profile from "../pages/Profile";
import Membership from "../pages/Membership";
import RenewMembership from "../pages/RenewMembership";

import Cart from "../pages/Cart";
import MainHeader from "../components/MainHeader";
import Sidebar from "../components/Sidebar";
import NotFound from "../pages/NotFound";
import axios from "axios";
import IndividualBookingsPage from "../pages/bookings/IndividualBookingsPage";

export default function UserRoutes(props) {
  const { component: Component, ...rest } = props;

  const [title, setTitle] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const cartContext = CartContext;

  function updateCart(cart) {
    cartContext.setCart(cart);
  }
  function updateTitle(title) {
    setTitle(title);
  }

  const authContext = useContext(AuthContext);
  const { token, user } = useContext(AuthContext);
  useEffect(() => {
    setIsLoading(true);
    //If user is not logged in, redirect to login page
    if (!token || !user) {
      history.replace("/login");
      return null;
    }

    if (!user) {
      history.replace("login");
      return null;
    }

    //If user log in for the first time,
    //Must changed password
    if (!user.isActive) {
      history.replace("/change-password");
      return null;
    }

    if (user && user.accType === "Admin") {
      history.replace("/admin" + history.location.pathname);
      return null;
    }

    axios.defaults.headers.common["Authorization"] = token;
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response != undefined && error.response.status == 401) {
          authContext.logout();
          history.replace("/login");
          return null;
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject();
      }
    );
    setIsLoading(false);
  }, []);

  if (user && user.accType == "Guest") {
    return (
      !isLoading &&
      user && (
        <>
          <Sidebar />
          <div id="main-content">
            <MainHeader title={title} />
            <Switch>
              <Route path={props.match.path} exact>
                <UserDashboard updateTitle={updateTitle} />
              </Route>
              <Route path={props.match.path + "calendar"} exact>
                <Calendar updateTitle={updateTitle} />
              </Route>
              <Route path="/events" exact>
                <Events updateTitle={updateTitle} />
              </Route>
              <Route path="/events/detail/:id" exact>
                <EventDetail updateTitle={updateTitle} />
              </Route>
              <Route path="/bookings" exact>
                <Bookings updateTitle={updateTitle} />
              </Route>
              <Route path={props.match.path + "/bookings/:id"} exact>
                <IndividualBookingsPage updateTitle={updateTitle} />
              </Route>
              <Route path="/membership" exact>
                <Membership updateTitle={updateTitle} />
              </Route>
              <Route path="/membership/renew" exact>
                <RenewMembership updateTitle={updateTitle} />
              </Route>
              <Route path="/profile" exact>
                <Profile updateTitle={updateTitle} />
              </Route>
              <Route path="/cart" exact>
                <Cart updateTitle={updateTitle} />
              </Route>
              <Route path="/cart/callback" exact>
                <Cart updateTitle={updateTitle} />
              </Route>
              <Redirect to="/404"></Redirect>
            </Switch>
          </div>
        </>
      )
    );
  }

  return (
    !isLoading &&
    user && (
      <>
        <Sidebar />
        <div id="main-content">
          <MainHeader title={title} />
          <Switch>
            <Route path={props.match.path} exact>
              <UserDashboard updateTitle={updateTitle} />
            </Route>
            <Route path={props.match.path + "calendar"} exact>
              <Calendar updateTitle={updateTitle} />
            </Route>
            <Route path="/events" exact>
              <Events updateTitle={updateTitle} />
            </Route>
            <Route path="/events/detail/:id" exact>
              <EventDetail updateTitle={updateTitle} />
            </Route>
            <Route path="/bookings" exact>
              <Bookings updateTitle={updateTitle} />
            </Route>
            <Route path={"/bookings/:id"} exact>
              <IndividualBookingsPage updateTitle={updateTitle} />
            </Route>
            <Route path="/membership" exact>
              <Membership updateTitle={updateTitle} />
            </Route>
            <Route path="/membership/renew" exact>
              <RenewMembership updateTitle={updateTitle} />
            </Route>
            <Route path="/profile" exact>
              <Profile updateTitle={updateTitle} />
            </Route>
            <Route path="/cart" exact>
              <Cart updateTitle={updateTitle} />
            </Route>
            <Route path="/cart/callback" exact>
              <Cart updateTitle={updateTitle} />
            </Route>
            <Redirect to="/404"></Redirect>
          </Switch>
        </div>
      </>
    )
  );
}
