import React, { useState, useEffect, useContext } from 'react';
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCol,
  CRow,
  CFormFeedback,
  CFormSelect,
} from '@coreui/react';
import formJSON from '../formConfigs/RenewMembership.json';
import Element from '../components/Element';
import axios from 'axios';
import { AuthContext } from '../hooks/AuthContext';
import { CartContext } from '../hooks/CartContext';
import { useHistory } from 'react-router';

export default function RenewMembership(props) {
  const [validated, setValidated] = useState(false);
  const [waiverAndReleaseVisiblity, setWaiverAndReleaseVisiblity] = useState(false);
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const [membership, setMembership] = useState(null);
  const [selectValue, setSelectValue] = useState('Please select a gender');
  const history = useHistory();
  let checkedValues = [];
  let waiverAndReleaseCheck = false;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const data = new FormData(event.target);
      var membershipDetails = {};
      if (data.get('status') == '' || data.get('status') == null) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        alert('Please select status');
      } else if (
        (data.get('waiverAndRelease') == null || data.get('waiverAndRelease') == '') &&
        (data.get('status').includes('Skater') || data.get('status').includes('Coach'))
      ) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        alert('Please tick the waiver');
      } else {
        data.forEach(function (value, key) {
          if (key.toLowerCase().includes('date')) {
            membershipDetails[key] = new Date(value);
          } else {
            if (key.toLowerCase() == 'status') {
              membershipDetails[key] = checkedValues;
            } else if (key.toLowerCase() == 'waiverAndRelease') {
              if (
                waiverAndReleaseCheck == 'I acknowledge, consent and agree to the Waiver and Release form (Annex A)'
              ) {
                membershipDetails[key] = 'Acknowledged';
              }
            } else {
              membershipDetails[key] = value;
            }
          }
        });
        let object = {};
        object.membershipId = membership._id;
        object.membershipDetails = membershipDetails;
        object.item = membership.membershipTitle;
        object.price = membership.membershipPrice;
        console.log(object);
        if (props.hasOwnProperty('updateMembershipDetails')) {
          props.updateMembershipDetails(object);
          props.nextStep();
          cartContext.addToCart(object);
        } else {
          if (cartContext.addToCart(object)) {
            history.push('/cart');
          }
        }
      }
    }
    setValidated(true);
  };

  function setValue(value) {
    console.log(value);
    setSelectValue(value);
  }

  const setCheckedValues = (values) => {
    setIsLoading(true);
    checkedValues = values;
    if (checkedValues.includes('Skater') || checkedValues.includes('Coach')) {
      setWaiverAndReleaseVisiblity(true);
    } else {
      setWaiverAndReleaseVisiblity(false);
    }
    setIsLoading(false);
  };

  const updateWaiverAndReleaseCheck = (values) => {
    setIsLoading(true);
    waiverAndReleaseCheck = values;
    setIsLoading(false);
  };

  const [elements, setElements] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setElements(formJSON[0]);
    setValidated(true);
    if (props.hasOwnProperty('updateTitle')) {
      props.updateTitle('Renew SISA Individual Annual Membership');
    }
    axios
      .get(process.env.REACT_APP_API_URL + '/memberships?gte=true')
      .then((res) => {
        const activeMembership = res.data.filter((membership) => {
          return new Date(membership.membershipEndDate) > Date.now();
        });
        console.log(activeMembership[0]);
        setMembership(activeMembership[0]);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
    setIsLoading(false);
  }, []);

  const { fields, page_label } = elements ?? {};

  const SubmitButtons = () => {
    if (props.hasOwnProperty('updateMembershipDetails')) {
      return (
        <>
          <CButton className='mt-3' type='submit'>
            Next
          </CButton>
        </>
      );
    }
    return (
      <>
        <CButton type='submit' className='mt-3'>
          Submit
        </CButton>
        <CButton type='reset' className='btn-danger ml-3 mt-3'>
          Reset
        </CButton>
      </>
    );
  };

  const ActualForm = () => {
    if (props.membershipDetails != null) {
      return (
        !isLoading && (
          <CCard>
            <CCardBody>
              {fields
                ? fields.map((field, i) => {
                    if (i == 0) {
                      return (
                        <Element
                          key={field.field_id}
                          field={field}
                          setCheckedValues={setCheckedValues}
                          waiverAndReleaseVisiblity={waiverAndReleaseVisiblity}
                          updateWaiverAndReleaseCheck={updateWaiverAndReleaseCheck}
                          waiverAndReleaseCheck={waiverAndReleaseCheck}
                          value={props.membershipDetails.membershipDetails[field.field_name]}
                        />
                      );
                    } else {
                      return (
                        <Element
                          key={field.field_id}
                          field={field}
                          className='mt-3'
                          setCheckedValues={setCheckedValues}
                          waiverAndReleaseVisiblity={waiverAndReleaseVisiblity}
                          updateWaiverAndReleaseCheck={updateWaiverAndReleaseCheck}
                          waiverAndReleaseCheck={waiverAndReleaseCheck}
                          value={props.membershipDetails.membershipDetails[field.field_name]}
                        />
                      );
                    }
                  })
                : null}
            </CCardBody>
          </CCard>
        )
      );
    }
    return (
      !isLoading && (
        <CCard>
          <CCardBody>
            {fields
              ? fields.map((field, i) => {
                  if (i == 0) {
                    return (
                      <Element
                        key={field.field_id}
                        field={field}
                        setCheckedValues={setCheckedValues}
                        waiverAndReleaseVisiblity={waiverAndReleaseVisiblity}
                        updateWaiverAndReleaseCheck={updateWaiverAndReleaseCheck}
                        waiverAndReleaseCheck={waiverAndReleaseCheck}
                      />
                    );
                  } else {
                    return (
                      <Element
                        key={field.field_id}
                        field={field}
                        className='mt-3'
                        setCheckedValues={setCheckedValues}
                        waiverAndReleaseVisiblity={waiverAndReleaseVisiblity}
                        updateWaiverAndReleaseCheck={updateWaiverAndReleaseCheck}
                        waiverAndReleaseCheck={waiverAndReleaseCheck}
                      />
                    );
                  }
                })
              : null}
          </CCardBody>
        </CCard>
      )
    );
  };

  return (
    !isLoading && (
      <>
        <CCard>
          <CCardBody>
            Please enter information on the form below to process subscription for SISA Individual Annual Membership.
            <br />
            <br />
            Please read item 2 & 3 before submitting your form, no printing required.
            <br />
            <br />
            Your SISA membership will be subjected to:
            <br />
            1) Verification of your club membership by the respective clubs. Please ensure that your club membership is
            current.
            <br />
            2) Kindly read, understand and check the box within the online form - Indemnity form (Annex A) for{' '}
            <a
              href='https://sisa-events-membership-server-dev.s3.ap-southeast-1.amazonaws.com/files/IndemnityFS.pdf'
              target='_blank'
            >
              Figure Skating
            </a>{' '}
            or{' '}
            <a
              href='https://sisa-events-membership-server-dev.s3.ap-southeast-1.amazonaws.com/files/IndemnityST.pdf'
              target='_blank'
            >
              Short Track
            </a>
            <br />
            3) Members who are looking at joining the Figure Skating Practice Sessions, kindly read, understand and
            check the box within the online form for:
            <ul>
              <li>
                <a
                  href='https://sisa-events-membership-server-dev.s3.ap-southeast-1.amazonaws.com/files/SafetyRecommendationComm.pdf'
                  target='_blank'
                >
                  Safety Recommendation
                </a>
              </li>
              <li>
                <a
                  href='https://sisa-events-membership-server-dev.s3.ap-southeast-1.amazonaws.com/files/GeneralSafetyCourtesyGuidelines.pdf'
                  target='_blank'
                >
                  Communication General Safety
                </a>
              </li>
              <li>
                <a
                  href='https://sisa-events-membership-server-dev.s3.ap-southeast-1.amazonaws.com/files/SafetyGuidelinesOnIce.pdf'
                  target='_blank'
                >
                  Courtesy Guidelines Safety Guidelines On Ice
                </a>
              </li>
            </ul>
            <br />
            4) Acceptance and confirmation of payment received. The SISA Membership expires every 31 March.
          </CCardBody>
          <CCardBody>
            <CForm className='needs-validation' noValidate validated={validated} onSubmit={handleSubmit}>
              <CRow>
                <CCol lg={12}>
                  <h5>Contact Details</h5>
                </CCol>
              </CRow>
              <ActualForm />
              <SubmitButtons />
            </CForm>
          </CCardBody>
        </CCard>
      </>
    )
  );
}
