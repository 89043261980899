import React, { useState, useEffect, useContext } from "react";
import {
  CForm,
  CRow,
  CCol,
  CFormFeedback,
  CFormLabel,
  CFormInput,
  CButton,
  CInputGroupText,
  CInputGroup,
  CFormSelect,
  CFormCheck,
  CCard,
  CCardBody,
  CButtonGroup,
} from "@coreui/react";
import MainHeader from "../components/MainHeader";
import axios from "axios";
import { AuthContext } from "../hooks/AuthContext";
import Membership from "./Membership";
import { useHistory } from "react-router";

export default function Profile(props) {
  const [validated, setValidated] = useState(false);
  const { user } = useContext(AuthContext);
  const [profile, setProfile] = useState(user);
  const [DOB, setDOB] = useState("");
  const [name, setName] = useState("Name");
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      alert(validated);
    }
    setValidated(true);
  };
  const history = useHistory();

  useEffect(() => {
    props.updateTitle("Profile");
    axios
      .get(process.env.REACT_APP_API_URL + "/users/" + user._id)
      .then((res) => {
        setProfile(res.data);
      });
  }, []);

  const authContext = useContext(AuthContext);
  function logout() {
    if (authContext.logout()) {
      history.replace("/login");
      history.push("/login");
    }
  }

  function changePassword() {
    history.push("/change-password");
  }

  const MembershipComponent = () => {
    if (user && user.accType != "Admin") {
      return <Membership />;
    }
    return null;
  };

  return (
    <>
      <CCard>
        <CCardBody>
          <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <CCol lg={12} className="mt-3">
              <CFormLabel htmlFor="validationCustom01">
                <strong>Name</strong>
              </CFormLabel>
              <CFormInput
                type="text"
                id="validationCustom01"
                defaultValue={profile.firstName + " " + profile.lastName}
                disabled
                required
              />
              <CFormFeedback invalid>Please provide a name.</CFormFeedback>
            </CCol>
            <CCol lg={12} className="mt-3">
              <CFormLabel htmlFor="validationCustom02">
                <strong>Email</strong>
              </CFormLabel>
              <CFormInput
                type="email"
                id="validationCustom02"
                defaultValue={user.email}
                required
                disabled
              />
              <CFormFeedback invalid>
                Please provide a valid email.
              </CFormFeedback>
            </CCol>
            <CCol lg={12} className="mt-3">
              <CFormLabel htmlFor="validationCustom05">
                <strong>Discipline</strong>
              </CFormLabel>
              <CFormInput
                type="text"
                id="validationCustom05"
                defaultValue={profile.discipline}
                required
                disabled
              />
            </CCol>
            <CCol lg={12} className="mt-3">
              <CFormLabel htmlFor="validationCustom05">
                <strong>Account Type</strong>
              </CFormLabel>
              <CFormInput
                type="text"
                id="validationCustom05"
                defaultValue={profile.accType}
                required
                disabled
              />
            </CCol>
            {/*<CCol lg={12}>
							<CFormCheck
								type="checkbox"
								id="invalidCheck"
								label="Agree to terms and conditions"
								required
							/>
							<CFormFeedback invalid>
								You must agree before submitting.
							</CFormFeedback>
						</CCol>*/}
          </CForm>
        </CCardBody>
        <MembershipComponent />
        <CCardBody>
          <CButton color="primary" onClick={() => changePassword()}>
            Change Password
          </CButton>
          <br />
          <CButton
            className="mt-3"
            color="danger"
            type="submit"
            onClick={() => logout()}
          >
            Log Out
          </CButton>
        </CCardBody>
      </CCard>
    </>
  );
}
