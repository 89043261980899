import EditForms from "../pages/EditForms";
import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import Events from "../pages/Events";
import AdminDashboard from "../pages/AdminDashboard";
import { AuthContext } from "../hooks/AuthContext";
import EventDetail from "../pages/EventDetail";
import Sidebar from "../components/Sidebar";
import CreateEvent from "../pages/events/CreateEvent";
import Calendar from "../pages/Calendar";
import Bookings from "../pages/Bookings";
import axios from "axios";
import AdminMembership from "../pages/AdminMembership";
import CreateMembership from "../pages/memberships/CreateMembership";
import Profile from "../pages/Profile";
import Users from "../pages/Users";
import CreateUser from "../pages/user/CreateUser";
import NotFound from "../pages/NotFound";
import Fields from "../pages/fields/Fields";
import Transactions from "../pages/Transactions";
import CreateField from "../pages/fields/CreateField";
import CreateEventCategory from "../pages/events/CreateEventCategory";
import EventCategories from "../pages/events/EventCategories";
import IndividualBookingsPage from "../pages/bookings/IndividualBookingsPage";

export default function AdminRoutes(props) {
  const authContext = useContext(AuthContext);
  const { component: Component, ...rest } = props;

  const [title, setTitle] = useState("");
  const [cart, setCart] = useState([{ item1: "yay" }]);
  const [events, setEvents] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  function updateCart(cart) {
    setCart(cart);
  }

  function updateTitle(title) {
    setTitle(title);
  }

  function updateEvents(events) {
    setEvents(events);
  }

  //redirect if there is no user or user is not admin
  //If user is not logged in, redirect to login page
  const { token, user } = useContext(AuthContext);

  useEffect(() => {
    if (user && user.accType != "Admin") {
      history.replace("/401");
      return null;
    }

    if (!token || !user) {
      history.replace("/login");
      return null;
    }

    //If user log in for the first time,
    //Must changed password
    if (!user.isActive) {
      history.replace("/change-password");
      return null;
    }

    axios.defaults.headers.common["Authorization"] = token;
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.toString().includes("Network Error")) {
          return Promise.reject();
        }
        if (error != null) {
          const {
            response: { status, data },
          } = error;
          try {
            if (status === 401) {
              authContext.logout();
              history.push("/login");
              return;
            }
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
          } catch (err) {
            console.log(err);
          }
        }
      }
    );
    setIsLoading(false);
  }, []);

  return (
    !isLoading &&
    user && (
      <>
        <Sidebar path={props.match.path} />
        <div id="main-content">
          <MainHeader title={title} />
          <Switch>
            <Route path={props.match.path} exact>
              <AdminDashboard updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/events"} exact>
              <Events updateTitle={updateTitle} updateEvents={updateEvents} />
            </Route>

            <Route path={props.match.path + "/events/create"} exact>
              <CreateEvent action="create" updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/events/edit/:id"} exact>
              <CreateEvent updateTitle={updateTitle} action="edit" />
            </Route>

            <Route path={props.match.path + "/events/detail/:id"} exact>
              <EventDetail action="detail" updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/fields"} exact>
              <Fields updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/fields/create"} exact>
              <CreateField updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/fields/edit/:id"} exact>
              <CreateField action="edit" updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/events/categories"} exact>
              <EventCategories updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/events/categories/create"} exact>
              <CreateEventCategory action="create" updateTitle={updateTitle} />
            </Route>

            <Route
              path={props.match.path + "/events/categories/edit/:id"}
              exact
            >
              <CreateEventCategory action="edit" updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/calendar"} exact>
              <Calendar action="detail" updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/bookings"} exact>
              <Bookings updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/bookings/:id"} exact>
              <IndividualBookingsPage updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/memberships"} exact>
              <AdminMembership updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/memberships/create"} exact>
              <CreateMembership action="create" updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/memberships/edit/:id"} exact>
              <CreateMembership action="edit" updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/profile"} exact>
              <Profile updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/users"} exact>
              <Users updateTitle={updateTitle} />
            </Route>

            <Route path={props.match.path + "/users/create"} exact>
              <CreateUser updateTitle={updateTitle} action="create" />
            </Route>

            <Route path={props.match.path + "/users/edit/:id"} exact>
              <CreateUser updateTitle={updateTitle} action="edit" />
            </Route>

            <Route path={props.match.path + "/users/details/:id"} exact>
              <CreateUser updateTitle={updateTitle} action="edit" />
            </Route>

            <Route path={props.match.path + "/transactions"} exact>
              <Transactions updateTitle={updateTitle} action="edit" />
            </Route>

            <Route path={props.match.path} exact>
              <AdminDashboard updateTitle={updateTitle} />
            </Route>

            <Redirect to="/404"></Redirect>
          </Switch>
        </div>
      </>
    )
  );
}
