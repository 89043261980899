import { CCard, CCardBody, CRow, CCol, CBadge, CButton } from "@coreui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { AuthContext } from "../../hooks/AuthContext";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import "../../assets/css/spinner.css";

export default function IndividualBookingsPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [booking, setBooking] = useState(null);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(async () => {
    setIsLoading(true);
    let res = await axios.get(
      process.env.REACT_APP_API_URL +
        "/bookings/" +
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ]
    );
    if (res.status == 200) {
      console.log(res.data);
      props.updateTitle("Booking ID: " + res.data._id);
      setBooking(res.data);
    } else {
    }
    setIsLoading(false);
  }, []);

  const UserName = () => {
    if (authContext.user.accType == "Admin") {
      return (
        <>
          <strong>User:</strong>
          <a href={"/users/details/" + booking.user._id}>
            {" "}
            {booking.user.firstName} {booking.user.lastName}
          </a>
        </>
      );
    }
    return null;
  };

  const exportBookingDetails = () => {
    setShowLoadingSpinner(true);
    axios({
      url:
        process.env.REACT_APP_API_URL +
        "/bookings/" +
        booking._id +
        "/additionalFields", //your url
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          }),
          downloadUrl = "",
          filename = "",
          disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        blob = new Blob([response.data], {
          type: "application/zip",
          filename: filename,
        });
        downloadUrl = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setShowLoadingSpinner(false);
      })
      .catch((err) => {
        setShowLoadingSpinner(false);
      });
  };

  const AdditionalFields = (props) => {
    if (
      booking.additionalFields != undefined &&
      Object.keys(booking.additionalFields).length > 0
    ) {
      return Object.keys(booking.additionalFields).map((eventId) => {
        if (eventId == props.event._id) {
          <div>Additional Input for this event</div>;
          return (
            <SingleFieldContent field={booking.additionalFields[eventId]} />
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  const SingleFieldContent = (field) => {
    console.log(field.field);
    return Object.keys(field.field).map((key) => {
      if (Array.isArray(field.field[key])) {
        var arrayValues = field.field[key];
        return arrayValues.map((val, index) => {
          if (val.toString().includes("http")) {
            return (
              <>
                <strong>{key}</strong>:<br />
                <a href={val} target="_blank">
                  {val}
                </a>
                <br />
              </>
            );
          } else if (index == arrayValues.length - 1) {
            return (
              <>
                {arrayValues[index]}
                <br />
              </>
            );
          } else if (index > 0) {
            return (
              <>
                {arrayValues[index]}
                {", "}
              </>
            );
          } else {
            return (
              <>
                <strong>{key}</strong>: {arrayValues[index]}
                {", "}
              </>
            );
          }
        });
      } else if (field.field[key].toString().includes("http")) {
        return (
          <>
            <strong>{key}</strong>:{" "}
            <a href={field} target="_blank">
              File
            </a>
            <br />
          </>
        );
      } else {
        return (
          <>
            <strong>{key}</strong>: {field.field[key]}
            <br />
          </>
        );
      }
    });
  };

  const override = css`
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1000;
  `;
  let [color, setColor] = useState("#ffffff");
  const LoadingSpinner = () => {
    return (
      showLoadingSpinner && (
        <MoonLoader color={color} loading={!isLoading} css={override} />
      )
    );
  };
  return (
    !isLoading &&
    booking.user && (
      <>
        <LoadingSpinner />
        <CCard>
          <CCardBody>
            <CButton onClick={() => exportBookingDetails()}>
              Export Booking Details
            </CButton>
            <br />
            <br />
            <UserName />
            <CRow className={"booking-item-details"}>
              <CCol>
                <strong>Invoice ID: </strong>
                <span>{booking.invoiceNo}</span>
                <br />
                <strong>Invoice Link: </strong>
                <a href={booking.invoiceLink} className="mt-1" target="_blank">
                  {booking.invoiceLink}
                </a>
              </CCol>
              {/*<CCol lg={3} className="booking-item-detail-col">
                        <strong>Status</strong>
                        {booking.status.toLowerCase() == "paid" && (
                          <CBadge className="badge-success badge-custom mt-1">
                            Paid
                          </CBadge>
                        )}
                        {booking.status.toLowerCase() == "pending" && (
                          <CBadge className="badge-warning badge-custom mt-1">
                            Pending
                          </CBadge>
                        )}
                        </CCol>*/}
              <CCol>
                <strong>Booking ID:</strong> {booking._id}
                <br />
              </CCol>
              <CCol>
                <strong>Created On:</strong>{" "}
                {moment(booking.createdAt).format("DD/MM/YYYY hh:mm:ss a")}
                <br />
              </CCol>
              <CCol>
                <strong>Total Price: </strong>${booking.price}
                <br />
                <strong>Payment Method: </strong>
                {booking.transaction.paymentMethod}
              </CCol>
            </CRow>

            <br />
            <strong>Events Booked</strong>

            {booking.events.map((event) => {
              return (
                <>
                  <CRow className="booking-event-item" key={event._id}>
                    <div className="booking-event-item-col">
                      <a href={"/events/detail/" + event._id}>
                        {event.eventName} |{" "}
                        {moment(event.startDate).format(
                          "DD/MM/YYYY hh:mm:ss a"
                        )}{" "}
                        {event.eventCategories != undefined &&
                        event.eventCategories.length > 0
                          ? "| "
                          : ""}
                      </a>
                      <CBadge className="badge-secondary">
                        {event.eventCategories != undefined &&
                        event.eventCategories.length > 0
                          ? event.eventCategories[0].name
                          : ""}
                      </CBadge>
                    </div>
                    <div className="booking-event-item-col">
                      <AdditionalFields event={event} />
                    </div>
                  </CRow>
                  <br />
                  <br />
                </>
              );
            })}
          </CCardBody>
        </CCard>
      </>
    )
  );
}
