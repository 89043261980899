import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CFormLabel,
  CContainer,
  CCol,
  CRow,
  CCardHeader,
  CBadge,
} from "@coreui/react";
import "../../assets/css/events.css";
import axios from "axios";
import { AuthContext } from "../../hooks/AuthContext";
import Modal from "../../components/Modal";
import { useHistory } from "react-router";
import moment from "moment";

export default function Fields(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const [modalLink, setModalLink] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [fields, setFields] = useState([]);

  //For Modal Functions
  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/fields");
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    document.title = "Fields";
    props.updateTitle("Fields");
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/fields")
      .then((res) => {
        setFields(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        displayModal(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  const columns = [
    {
      name: "Field ID",
      selector: (row) => row.field_id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.field_name != null ? row.field_name : ""),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.field_type,
      sortable: true,
    },
    {
      name: "Values",
      selector: (row) => row.field_values,
      sortable: true,
      cell: (row) => {
        return row.field_values.map((val) => {
          return <div key={val}>{val + ", "}</div>;
        });
      },
    },
    {
      name: "Required",
      sortable: true,
      selector: (row) => row.field_required,
    },
  ];

  const onRowClicked = (e) => {
    history.push("/fields/edit/" + e._id);
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log(selectedRows);
  };

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          message={modalMessage}
          mode={modalMode}
          link={modalLink}
        ></Modal>
        <CCard>
          <CCardBody>
            <CCard>
              <CCardHeader>
                <CButton
                  className="mr-2"
                  onClick={() => history.push("/fields/create")}
                >
                  Add Field
                </CButton>
              </CCardHeader>
              <CCardBody>
                <DataTable
                  columns={columns}
                  data={fields}
                  pagination
                  onSelectedRowsChange={handleChange}
                  onRowClicked={onRowClicked}
                />
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </>
    )
  );
}
