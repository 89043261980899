import { CCard, CCardBody, CBadge, CButton, CCardHeader } from "@coreui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import Modal from "../components/Modal";

export default function Users(props) {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  /*const filteredItems = fakeUsers.filter(s
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );*/

  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/users");
        break;
      case "Yes":
        deleteUsers();
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log(selectedRows);
  };

  const columns = [
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Account Type",
      selector: (row) => row.accType,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => row.isActive,
      sortable: true,
      cell: (row) => (
        <CBadge
          className={
            row.isActive == true ? "badge badge-success" : "badge-danger"
          }
        >
          {row.isActive == true ? "Active" : "Not Active"}
        </CBadge>
      ),
    },
    {
      name: "Approved",
      selector: (row) => row.isApproved,
      sortable: true,
      cell: (row) => (
        <CBadge
          className={
            row.isApproved == true ? "badge badge-success" : "badge-danger"
          }
        >
          {row.isApproved == true ? "Approved" : "Not Approved"}
        </CBadge>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row.isApproved,
      sortable: true,
      cell: (row) => {
        if (row.isApproved == false) {
          return (
            <CButton
              onClick={() => approveUser(row)}
              className="btn-custom btn-small"
            >
              Approve User
            </CButton>
          );
        } else {
          return (
            <CButton
              onClick={() => disableUser(row)}
              className="btn-danger btn-small"
            >
              Disable User
            </CButton>
          );
        }
      },
    },
  ];

  const approveUser = (e) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/approve", {
        userId: e._id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setModalMode("Info");
          displayModal(
            "Successfully approved user: " +
              res.data.firstName +
              " " +
              res.data.lastName
          );
          history.push("/users");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disableUser = (e) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/disable", {
        userId: e._id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setModalMode("Info");
          displayModal(
            "Successfully disabled user: " +
              res.data.firstName +
              " " +
              res.data.lastName
          );
          history.push("/users");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRowClicked = (e) => {
    history.push("/users/details/" + e._id);
  };

  const addUser = (e) => {
    history.push("/users/create");
  };

  const deleteRows = (e) => {
    console.log(selectedRows.length);
    if (selectedRows.length > 0) {
      setModalMode("Confirm");
      displayModal("Confirm delete selected users?");
    } else {
      setModalMode("Error");
      displayModal("Please select a user to delete");
    }
  };

  const deleteUsers = (e) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/users/delete", {
        users: selectedRows,
      })
      .then((res) => {
        setModalMode("Info");
        displayModal(res.data.message);
      });
  };

  useEffect(() => {
    props.updateTitle("Users");
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/users")
      .then((res) => {
        setUsers(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, []);

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          message={modalMessage}
          mode={modalMode}
        ></Modal>
        <CCard>
          <CCardBody>
            <CCard>
              <CCardHeader>
                <CButton className="mr-2" onClick={() => addUser()}>
                  Add User
                </CButton>
                <CButton className="btn-danger" onClick={() => deleteRows()}>
                  Delete User
                </CButton>
              </CCardHeader>
              <CCardBody>
                <DataTable
                  columns={columns}
                  data={users}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  onRowClicked={onRowClicked}
                />
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </>
    )
  );
}
