import React, { Component, useState, useContext, useEffect } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CLink,
  CCol,
  CRow,
  CFormInput,
  CContainer,
  CFormLabel,
} from "@coreui/react";
import axios from "axios";
import { AuthContext } from "../hooks/AuthContext";
import useFindUser from "../hooks/useFindUser";
import { useHistory } from "react-router";
import Modal from "../components/Modal";
import "../assets/css/login.css";
import CIcon from "@coreui/icons-react";
import logo from "../assets/icons/sisa_icon.jpg";
require("dotenv").config();

export default function LoginGuest() {
  document.title = "Login";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { updateUser } = useFindUser();
  const authContext = useContext(AuthContext);
  const history = useHistory();

  //Modal Related
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalMode, setModalMode] = useState("Info");

  function displayModal(title, message) {
    setShowModal(true);
    setModalTitle(title);
    setModalMessage(message);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  function login() {
    axios
      .post(process.env.REACT_APP_API_URL + "/guest", {
        email: email,
      })
      .then((res) => {
        if (res.data.message == "Wrong email or password") {
          displayModal("Invalid credentials", res.data.message);
        } else {
          if (
            authContext.login(res.data, null) &&
            res.data.accType == "Admin"
          ) {
            history.push("/admin");
          } else {
            history.replace("/");
          }
        }
      })
      .catch((err) => {
        displayModal(
          "Invalid credentials",
          "Something went wrong, please try again"
        );
      });
  }

  function onKeyDown(e) {
    if (e.keyCode == 13) {
      login();
    }
  }

  const { token, user } = useContext(AuthContext);

  return (
    <>
      <div className="d-flex bg-light border login-card">
        <CCard style={{ width: "100%" }}>
          <CCardBody>
            <CCol>
              <div>
                <CIcon className="logo-small" src={logo} />
                <h2>Events Portal</h2>
              </div>
              <h4>Continue as Guest</h4>
              <CFormLabel>Please enter your email</CFormLabel>
              <CFormInput
                className="mb-3"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => onKeyDown(e)}
              />
              <CButtonGroup className="mt-3">
                <CButton onClick={() => login()}>Login</CButton>
              </CButtonGroup>
              <br />
              <br />
              <CLink href="/login">Are you a member? Sign in here</CLink>
            </CCol>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
}
