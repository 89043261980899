import React, { useState, useEffect, useContext } from "react";
import formJSON from "../../formConfigs/CreateMembership.json";
import Element from "../../components/Element";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CForm,
  CCardHeader,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCol,
  CRow,
  CFormFeedback,
  CFormSelect,
} from "@coreui/react";
import axios from "axios";
import { AuthContext } from "../../hooks/AuthContext";
import { useForm } from "react-hook-form";
import Modal from "../../components/Modal";
import { useHistory } from "react-router";

export default function CreateMembership(props) {
  const [validated, setValidated] = useState(false);
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  //Form Fields
  const [membership, setMembership] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const history = useHistory();

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/memberships");
        break;
      case "Yes":
        deletemembership();
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const data = new FormData(e.target);
      var object = {};
      data.forEach(function (value, key) {
        if (key.toLowerCase().includes("date")) {
          object[key] = new Date(value);
        } else {
          object[key] = value;
        }
      });
      if (props.action === "edit") {
        axios
          .put(
            process.env.REACT_APP_API_URL + "/memberships/" + membership._id,
            object
          )
          .then((res) => {
            if (res.status === 200) {
              displayModal(
                "Successfully updated membership: " + res.data.membershipTitle
              );
            }
            if (res.status != 200) {
              displayModal(
                "Failed to update membership: " + membership.membershipTitle
              );
            }
          })
          .catch((err) => {
            displayModal(
              "Failed to update membership: " + membership.membershipTitle
            );
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_URL + "/memberships", object)
          .then((res) => {
            console.log(res.status === 200);
            if (res.status === 200) {
              setModalMode("Info");
              displayModal(
                "Successfully created membership: " + res.data.membershipTitle
              );
            } else {
              setModalMode("Info");
              displayModal(
                "Failed to create membership: " + res.data.membershipTitle
              );
            }
          })
          .catch((err) => {
            displayModal("Failed to create membership: " + err);
          });
      }
    }
    setValidated(true);
  };

  const deletemembership = (e) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/memberships/" + membership._id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setModalMode("Info");
          displayModal(
            "Successfully deleted membership: " + membership.membershipTitle
          );
        }
        if (res.status != 200) {
          displayModal(
            "Failed to delete membership: " + membership.membershipTitle
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function confirmDeletemembership() {
    setModalMode("Confirm");
    displayModal("Confirm delete membership: " + membership.membershipTitle);
  }

  const [elements, setElements] = useState(null);
  useEffect(() => {
    setElements(formJSON[0]);
    if (props.action === "edit") {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/memberships/" +
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ]
        )
        .then((res) => {
          console.log(res.data);
          let membership = res.data;
          setMembership(res.data);
          setValue("membershipTitle", res.data.membershipTitle);
          props.updateTitle("Edit Membership: " + res.data.membershipTitle);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      props.updateTitle("Create Membership");
      setIsLoading(false);
    }
  }, []);

  const { fields, page_label } = elements ?? {};

  /** Listener for all form fields **/
  function handlemembershipNameChange(e) {
    this.setState({ email: e.target.value });
  }

  function Users() {
    if (membership.users) {
      {
        return membership.users.map((user) => {
          return (
            <CRow>
              <CCol lg={8}>
                <a href={"/users/details/" + user._id}>
                  {user.firstName + " " + user.lastName}
                </a>
              </CCol>
              {/*<CCol lg={4}>
                <a
                  href={user.transaction ? user.transaction.invoiceLink : "#"}
                  target="_blank"
                >
                  Invoice Link
                </a>
          </CCol>*/}
            </CRow>
          );
        });
      }
    } else {
      return <>No users subscribed to this membership yet</>;
    }
  }

  if (props.action === "edit") {
    return (
      !isLoading &&
      membership && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field, i) => {
                      if (membership[field.field_name]) {
                        if (i == 0) {
                          return (
                            <Element
                              key={i}
                              field={field}
                              value={membership[field.field_name]}
                            />
                          );
                        } else {
                          return (
                            <Element
                              key={i}
                              field={field}
                              value={membership[field.field_name]}
                              className="mt-3"
                            />
                          );
                        }
                      } else {
                        if (i == 0) {
                          return <Element key={i} field={field} />;
                        } else {
                          return (
                            <Element key={i} field={field} className="mt-3" />
                          );
                        }
                      }
                    })
                  : null}

                <CButtonGroup className="mt-3">
                  <CButton type="submit">Save</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeletemembership()}
                  >
                    Delete
                  </CButton>
                </CButtonGroup>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardBody>
              <CCard>
                <CCardHeader>
                  <strong>Users</strong>
                </CCardHeader>
                <CCardBody>
                  <Users />
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </>
      )
    );
  } else {
    return (
      !isLoading && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field, i) => {
                      if (i == 0) {
                        return <Element key={i} field={field} />;
                      } else {
                        return (
                          <Element key={i} field={field} className="mt-3" />
                        );
                      }
                    })
                  : null}

                <CButtonGroup className="mt-3">
                  <CButton type="submit">Submit</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeletemembership()}
                  >
                    Delete
                  </CButton>
                </CButtonGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </>
      )
    );
  }
}
