import React, { useState, useEffect, useContext } from "react";
import EventCard from "../components/EventCard";
import DataTable from "react-data-table-component";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CFormLabel,
  CContainer,
  CCol,
  CRow,
  CCardHeader,
  CBadge,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import "../assets/css/events.css";
import { CartContext } from "../hooks/CartContext";
import axios from "axios";
import { AuthContext } from "../hooks/AuthContext";
import { useHistory } from "react-router";
import moment from "moment";

function Events(props) {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [userBookings, setUserBookings] = useState([]);
  const history = useHistory();
  const [membership, setMembership] = useState(false);
  const cartContext = useContext(CartContext);

  useEffect(() => {
    document.title = "Events";
    props.updateTitle("Events");
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/events")
      .then((res) => {
        let events = res.data;
        //TODO: Move the filtering of events returned base on user discipline from backend
        if (user != undefined && user.discipline == "Short Track") {
          setEvents(
            [...events].filter(
              (item) =>
                item.eventType == "Short Track" || item.eventType == "General"
            )
          );
          setFilteredEvents(
            [...events].filter(
              (item) =>
                item.eventType == "Short Track" || item.eventType == "General"
            )
          );
        } else if (user != undefined && user.discipline == "Both") {
          setEvents(events);
          setFilteredEvents(events);
        } else if (user != undefined && user.discipline == "Figure Skating") {
          setEvents(
            [...events].filter(
              (item) =>
                item.eventType == "Figure Skating" ||
                item.eventType == "General"
            )
          );
          setFilteredEvents(
            [...events].filter(
              (item) =>
                item.eventType == "Figure Skating" ||
                item.eventType == "General"
            )
          );
        } else {
          setEvents(events);
          setFilteredEvents(events);
        }
      })
      .catch((err) => {});
    axios
      .get(process.env.REACT_APP_API_URL + "/users/" + user._id + "/membership")
      .then((res) => {
        console.log(res.data);
        if (res.data.message == "This user has no active membership") {
          setMembership(false);
        } else {
          setMembership(true);
        }
      })
      .catch((err) => {});
    axios
      .get(process.env.REACT_APP_API_URL + "/users/" + user._id + "/bookings")
      .then((res) => {
        setUserBookings(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, []);

  function search(filter) {
    if (filter != "") {
      setFilteredEvents(
        filteredEvents.filter(
          (event) =>
            event.durationString.toLowerCase().includes(filter.toLowerCase()) ||
            event.eventName.toLowerCase().includes(filter.toLowerCase())
        )
      );
    } else {
      setFilteredEvents(events);
    }
  }

  function sort(filter) {
    setSortBy(filter);
    if (filter == "earliest") {
      setFilteredEvents(
        [...filteredEvents].sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
      );
    } else if (filter == "latest") {
      setFilteredEvents(
        [...filteredEvents].sort((a, b) => (a.startDate < b.startDate ? 1 : -1))
      );
    } else {
      setFilteredEvents(events);
    }
  }

  function sortType(filter) {
    setSortBy(filter);
    if (filter == "Short Track") {
      setFilteredEvents(
        [...events].filter((item) => item.eventType == "Short Track")
      );
    } else if (filter == "General") {
      setFilteredEvents(
        [...events].filter((item) => item.eventType == "General")
      );
    } else if (filter == "Figure Skating") {
      setFilteredEvents(
        [...events].filter((item) => item.eventType == "Figure Skating")
      );
    } else {
      setFilteredEvents(events);
    }
  }

  function addToCart(event) {
    if (
      cartContext.addToCart({
        item: event.eventName,
        description: event.startDate + " - " + event.endDate,
        price: event.price,
        event: event,
      })
    ) {
      //setIsInCart(true);
    }
  }

  function removeFromCart(event) {
    cartContext.removeFromCart({
      item: event.eventName,
      description: event.startDate + " - " + event.endDate,
      price: event.price,
      event: event,
    });
    //setIsInCart(false);
  }

  const addEvent = (e) => {
    history.push("/events/create");
  };

  const columns = [
    {
      name: "Event Name",
      selector: (row) => row.eventName,
      sortable: true,
    },
    {
      name: "Event Type",
      selector: (row) => row.eventType,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.startDate).format("DD/MM/YYYY hh:mm:ss a"),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => moment(row.endDate).format("DD/MM/YYYY hh:mm:ss a"),
      sortable: true,
    },
    {
      name: "Capacity",
      sortable: true,
      selector: (row) => row.capacity,
    },
    {
      name: "Current Bookings",
      sortable: true,
      selector: (row) => row.bookings,
    },
    {
      name: "Actions",
      cell: (row) => (
        <CButton onClick={() => addToCart(row)}>Add To Cart</CButton>
      ),
    },
  ];

  const onRowClicked = (e) => {
    history.push("/events/details/" + e._id);
  };

  function EventActionButtons() {
    if (user.accType == "Admin") {
      return (
        <CCardHeader>
          <CButton className="mr-2" onClick={() => addEvent()}>
            Add Event
          </CButton>
        </CCardHeader>
      );
    } else {
      return null;
    }
  }

  return (
    !isLoading && (
      <>
        <CCard>
          <EventActionButtons />
          <CCardBody>
            <CRow>
              <CCol lg={4} md={6}>
                <CFormInput
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => search(e.target.value)}
                />
              </CCol>
              <CCol lg={4} md={6}>
                <CFormSelect
                  className="form-control"
                  onChange={(e) => sortType(e.target.value)}
                >
                  <option value="Sort By">Sort By Event Type</option>
                  <option value="Figure Skating">Figure Skating</option>
                  <option value="Short Track">Short Track</option>
                  <option value="General">General</option>
                </CFormSelect>
              </CCol>
              <CCol lg={4} md={6}>
                <CFormSelect
                  className="form-control"
                  onChange={(e) => sort(e.target.value)}
                >
                  <option value="Sort By">Sort By Date</option>
                  <option value="earliest">Earliest Date</option>
                  <option value="latest">Latest Date</option>
                </CFormSelect>
              </CCol>
            </CRow>
            {/*<DataTable
              columns={columns}
              data={filteredEvents}
              pagination
              onRowClicked={onRowClicked}
            />*/}

            <CRow className="mt-20">
              {filteredEvents.map((item) => {
                return (
                  <CCol lg={4} md={6} sm={12} className="mb-3" key={item._id}>
                    <EventCard
                      event={item}
                      userBookings={userBookings}
                      userMembership={membership}
                    />
                  </CCol>
                );
              })}
            </CRow>
          </CCardBody>
        </CCard>
      </>
    )
  );
}

export default React.memo(Events);
