import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CBadge,
  CButton,
  CButtonGroup,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import moment from "moment";
import Modal from "../components/Modal";

export default function AdminMembership(props) {
  const [memberships, setMemberships] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/memberships");
        break;
      case "Yes":
        deleteMemberships();
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log(selectedRows);
  };

  useEffect(() => {
    props.updateTitle("Memberships");
    axios.get(process.env.REACT_APP_API_URL + "/memberships").then((res) => {
      console.log(res);
      setMemberships(res.data);
      setIsLoading(false);
    });
  }, []);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.membershipTitle,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.membershipStartDate,
      sortable: true,
      cell: (row) => (
        <>{moment(row.membershipStartDate).format("DD MMM YYYY")}</>
      ),
    },
    {
      name: "End Date",
      selector: (row) => row.membershipEndDate,
      sortable: true,
      cell: (row) => <>{moment(row.membershipEndDate).format("DD MMM YYYY")}</>,
    },
    {
      name: "Price",
      selector: (row) => row.membershipPrice,
      sortable: true,
      cell: (row) => <>${row.membershipPrice}</>,
    },
    {
      name: "Total Users",
      selector: (row) => row.users.length,
      sortable: true,
    },
  ];

  const createMembership = (e) => {
    history.push("/memberships/create");
  };

  const onRowClicked = (e) => {
    history.push("/memberships/edit/" + e._id);
  };

  const deleteMemberships = () => {
    let object = { memberships: [] };
    selectedRows.forEach((membershipToDelete) => {
      object.memberships.push(membershipToDelete._id);
    });
    axios
      .delete(process.env.REACT_APP_API_URL + "/memberships", {
        data: object,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setSelectedRows([]);
          axios
            .get(process.env.REACT_APP_API_URL + "/memberships")
            .then((res) => {
              console.log(res);
              setMemberships(res.data);
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteRows = (e) => {
    console.log(selectedRows.length);
    if (selectedRows.length > 0) {
      setModalMode("Confirm");
      displayModal("Confirm delete selected memberships?");
    } else {
      setModalMode("Error");
      displayModal("Please select a membership to delete");
    }
  };

  if (memberships.length == 0) {
    return (
      !isLoading && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CCard>
                <CCardBody>
                  No memberships found
                  <br />
                  <CButton onClick={() => history.push("/memberships/create")}>
                    Create Membership
                  </CButton>
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </>
      )
    );
  } else {
    return (
      !isLoading && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CCard>
                <CCardHeader>
                  <CButton className="mr-2" onClick={() => createMembership()}>
                    Add New Membership
                  </CButton>
                  <CButton onClick={() => deleteRows()} className="btn-danger">
                    Delete
                  </CButton>
                </CCardHeader>
                <DataTable
                  columns={columns}
                  data={memberships}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  onRowClicked={onRowClicked}
                />
              </CCard>
            </CCardBody>
          </CCard>
        </>
      )
    );
  }
}
