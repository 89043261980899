import React, { useEffect, useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CForm,
} from "@coreui/react";
import Element from "./Element";
import localforage from "localforage";
import axios from "axios";
import Modal from "./Modal";
import moment from "moment";

export default function AdditionalUserInput({
  handleClose,
  show,
  title,
  message,
  mode,
  fields,
  submitListener,
  event,
}) {
  const [inputFields, setInputFields] = useState(
    fields != undefined && fields != null ? fields : null
  );
  const [validated, setIsValidated] = useState(false);
  const [elements, setElements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileFormData, setFileFormData] = useState(new FormData());
  const [files, setFiles] = useState([]);
  const [field_name, setFieldName] = useState(null);

  const updateFormData = (field_name, files_) => {
    if (files != undefined && files != null) {
      let filesArr = files;
      let actualFiles = [];
      files_.map((file) => {
        actualFiles.push(file);
      });
      filesArr.push({
        field_name: field_name,
        files: actualFiles,
      });
      console.log(filesArr);
      setFiles(filesArr);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fields.map((field) => {
      if (field.field_values != undefined && field.field_values.length > 0) {
        field.field_options = [];
        field.field_values.map((option) => {
          field.field_options.push(
            (option = {
              option_label: option,
              option_value: option,
            })
          );
        });
      }
      inputFields.map((option) => {
        field.field_label = field.field_name;
        field.field_mandatory = field.field_required;
      });
    });
    setInputFields(fields);
    setIsLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setIsValidated(true);
    } else {
      e.preventDefault();
      const data = new FormData(e.target);
      var object = {};
      let promises = [];
      files.map(async (fileObj) => {
        promises.push(
          new Promise(async (resolve, reject) => {
            let formData = new FormData();
            fileObj.files.map((file) => {
              formData.append("files", file.file, file.file.originalname);
            });
            formData.append(
              "directory",
              event.eventName +
                "/" +
                moment(new Date()).format("DD-MM-YYYY-HH-MM-SS")
            );
            let uploaded = await axios({
              method: "post",
              url: process.env.REACT_APP_API_URL + "/upload/multiple",
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
            });
            console.log(uploaded);
            if (uploaded.status == 500) {
              //display error modal
              reject();
            }
            object[fileObj.field_name] = new Map();
            object[fileObj.field_name] = uploaded.data.data;
            console.log(object[fileObj.field_name]);
            resolve();
          })
        );
      });
      await Promise.all(promises);
      data.forEach(function (value, key) {
        console.log(value + " : " + key);
        if (key.toLowerCase().includes("date")) {
          object[key] = new Date(value);
        } else if (key == "eventCategories") {
          if (value == "none") {
            object[key] = [];
          } else {
            object[key] = value;
          }
        } else if (key == "fields") {
          if (value == "") {
            object[key] = [];
          } else if (object[key] != undefined) {
            console.log(object[key]);
            let val = object[key];
            val.push(value);
            object[key] = val;
            console.log(object[key]);
          } else {
            object[key] = [value];
          }
        } else {
          if (object[key] != undefined && object[key] != null) {
            if (Array.isArray(object[key])) {
              let arr = object[key];
              arr.push(value);
              object[key] = arr;
            } else {
              let arr = [];
              arr[0] = object[key];
              arr.push(value);
              object[key] = arr;
            }
          } else {
            object[key] = value;
          }
        }
      });
      console.log(object["File Upload"]);
      submitListener(object);
      handleClose("Confirm and Add To Cart");
    }
  };

  switch (mode) {
    case "Info":
      return null;
    case "Add To Cart":
      return (
        !isLoading && (
          <>
            <Modal />
            <CModal visible={show} onClose={() => handleClose()}>
              <CModalHeader>
                <CModalTitle>{title}</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <CForm
                  className="needs-validation"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  {inputFields
                    ? inputFields.map((field, i) => {
                        if (i == 0) {
                          return (
                            <Element
                              key={i}
                              field={field}
                              formData={updateFormData}
                            />
                          );
                        } else {
                          return (
                            <Element
                              key={i}
                              field={field}
                              formData={updateFormData}
                              className="mt-3"
                            />
                          );
                        }
                      })
                    : null}
                  <CModalFooter>
                    <CButton color="primary" type="submit">
                      Confirm and Add To Cart
                    </CButton>
                  </CModalFooter>
                </CForm>
              </CModalBody>
            </CModal>
          </>
        )
      );
  }
}
