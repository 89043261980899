import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../hooks/AuthContext";
import DataTable from "react-data-table-component";
import Modal from "../components/Modal";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CButtonGroup,
  CButton,
  CBadge,
} from "@coreui/react";
import { useHistory } from "react-router";
import axios from "axios";
import moment from "moment";
export default function Transactions(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);

  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const [modalLink, setModalLink] = useState("");
  const [transactions, setTransactions] = useState([]);
  const history = useHistory();

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/transactions");
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log(selectedRows);
  };

  function exportToCsv() {
    axios
      .post(process.env.REACT_APP_API_URL + "/transactions/downloadCSV")
      .then((res) => {
        setModalMode("Link");
        setModalLink(res.data.message);
        displayModal("Download at: ");
      })
      .catch((err) => {
        console.log(err);
        setModalMode("Error");
        displayModal(err.message);
      });
  }

  useEffect(() => {
    document.title = "Transactions";
    props.updateTitle("Transactions");
    axios
      .get(process.env.REACT_APP_API_URL + "/transactions")
      .then((res) => {
        console.log(res.data);
        setTransactions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) =>
        row.user != null ? row.user.firstName + " " + row.user.lastName : "",
      sortable: true,
    },
    {
      name: "Invoice No",
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: "Invoice Link",
      selector: (row) => row.invoiceLink,
      sortable: true,
    },
    {
      name: "Transaction Type",
      sortable: true,
      cell: (row) => (
        <div>
          {row.booking != undefined
            ? "Booking"
            : row.membershipId
            ? "Membership"
            : "Unknown"}
        </div>
      ),
    },
    {
      name: "Payment Provider",
      sortable: true,
      selector: (row) => row.paymentProvider,
    },
    {
      name: "Payment Method",
      sortable: true,
      selector: (row) => row.paymentMethod,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <CBadge
          className={
            row.status == "Paid" || row.status == "completed"
              ? "badge badge-success"
              : row.status == "pending"
              ? "badge badge-warning"
              : row.refunded
              ? "badge-danger"
              : "badge-secondary"
          }
        >
          {row.status}
        </CBadge>
      ),
    },
    {
      name: "Amount",
      sortable: true,
      selector: (row) => "$" + row.price,
    },
    {
      name: "Created At",
      sortable: true,
      selector: (row) => moment(row.createdAt).format("DD/MM/YY hh:mm:ss A"),
    },
  ];

  const onRowClicked = (e) => {
    history.push("/transactions/details/" + e._id);
  };

  const deleteRows = (e) => {
    console.log(selectedRows.length);
    if (selectedRows.length > 0) {
      setModalMode("Confirm");
      displayModal("Confirm delete selected transactions?");
    } else {
      setModalMode("Error");
      displayModal("Please select a transaction to delete");
    }
  };

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          message={modalMessage}
          mode={modalMode}
          link={modalLink}
        ></Modal>
        <CCard>
          <CCardHeader>
            <CButton onClick={() => exportToCsv()}>Export to CSV</CButton>
          </CCardHeader>
          <CCardBody>
            <CCard>
              <CCardBody>
                <DataTable
                  columns={columns}
                  data={transactions}
                  pagination
                  onSelectedRowsChange={handleChange}
                  onRowClicked={onRowClicked}
                />
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </>
    )
  );
}
