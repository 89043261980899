import React, { useState, useEffect, useCallback } from "react";

import Cart from "./pages/Cart";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Unauthorized from "./pages/Unauthorized";

import AdminRoutes from "./routes/AdminRoutes";
import UserRoutes from "./routes/UserRoutes";
import Breadcrumbs from "./components/BreadCrumbs";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./assets/css/style.css";
import { AuthContext } from "./hooks/AuthContext";
import { useHistory } from "react-router-dom";
import { CartContext } from "./hooks/CartContext";
import LoginGuest from "./pages/LoginGuest";
import ChangePassword from "./pages/ChangePassword";
import PublicSignUp from "./pages/PublicSignUp";
import GuestSignUp from "./pages/GuestSignUp";
import ResetPassword from "./pages/ResetPassword";
//  stateless functional component
//  always return JSX
require("dotenv").config();

export default function App(props) {
  const [events, setEvents] = useState([]);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState([]);
  const [tokenExpirationTime, setTokenExpirationTime] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const login = (userData, expirationTime) => {
    setToken(userData.token);
    setUser(userData);
    //default session set to 24H
    const expiration =
      expirationTime || new Date(new Date().getTime() + 24000 * 60 * 60);
    setTokenExpirationTime(expiration);
    localStorage.removeItem("userDataKey");
    localStorage.setItem(
      "userDataKey",
      JSON.stringify({
        userData,
        expirationTime: expiration.toISOString(),
      })
    );
    setIsLoading(false);
    return true;
  };

  const logout = () => {
    console.log("Logging out...");
    setIsLoading(true);
    setToken(null);
    setTokenExpirationTime(null);
    setUser(null);
    localStorage.removeItem("userDataKey");
    localStorage.removeItem("cart");
    emptyCart();
    setIsLoading(false);
    return true;
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userDataKey"));
    if (
      storedData &&
      storedData.userData.token &&
      new Date(storedData.expirationTime) > new Date()
    ) {
      setIsLoading(true);
      login(storedData.userData, new Date(storedData.expirationTime));
    } else if (
      storedData &&
      new Date(storedData.expirationTime) <= new Date()
    ) {
      alert("Your session has expired. Please login again.");
      setToken(null);
      localStorage.removeItem("userDataKey");
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    setIsLoading(true);
    const cart = JSON.parse(localStorage.getItem("cart"));
    if (cart) {
      setCart(cart.currentCart);
    }
    setIsLoading(false);
  }, []);

  const addToCart = (event) => {
    let currentCart = cart.slice();
    console.log(currentCart.includes(event));
    let invalid = false;
    //Only events OR membership can be added to cart at the same time
    currentCart.forEach((item) => {
      if (
        item.hasOwnProperty("membershipId") &&
        event.hasOwnProperty("event")
      ) {
        alert("Not allowed to add membership and event together in cart");
        invalid = true;
        return false;
      } else if (
        item.hasOwnProperty("event") &&
        event.hasOwnProperty("membershipId")
      ) {
        alert("Not allowed to add membership and event together in cart");
        invalid = true;
        return false;
      } else if (event.hasOwnProperty("membershipId")) {
        if (item.membershipId == event.membershipId) {
          invalid = true;
          return false;
        }
      }
    });

    if (!invalid) {
      if (!currentCart.includes(event)) {
        currentCart.push(event);
      }
      setCart(currentCart);
      localStorage.setItem(
        "cart",
        JSON.stringify({
          currentCart,
        })
      );
      return true;
    }
  };

  const removeFromCart = (event) => {
    console.log(event);
    cart.map((cartItem) => {
      if (
        cartItem.hasOwnProperty("event") &&
        cartItem.event._id == event.event._id
      ) {
        let currentCart = cart.slice();
        currentCart.pop(cartItem);
        setCart(currentCart);
        localStorage.setItem(
          "cart",
          JSON.stringify({
            currentCart,
          })
        );
      }
    });

    cart.map((cartItem) => {
      if (
        cartItem.hasOwnProperty("membershipId") &&
        cartItem.membershipId == event.membershipId
      ) {
        let currentCart = cart.slice();
        currentCart.pop(cartItem);
        setCart(currentCart);
        localStorage.setItem(
          "cart",
          JSON.stringify({
            currentCart,
          })
        );
      }
    });
  };

  const emptyCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  return (
    !isLoading && (
      <AuthContext.Provider
        value={{
          user,
          token,
          login,
          logout,
        }}
      >
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/login-guest" component={LoginGuest}></Route>
          <Route path="/admin" component={AdminRoutes}></Route>
          <Route path="/404" component={NotFound}></Route>
          <Route path="/401" component={Unauthorized}></Route>
          <Route path="/change-password" component={ChangePassword}></Route>
          <Route
            path="/reset-password/:resetToken"
            component={ResetPassword}
          ></Route>
          <CartContext.Provider
            value={{ cart, addToCart, removeFromCart, emptyCart }}
          >
            <Switch>
              <Route path="/guest/sign-up" component={GuestSignUp}></Route>
              <Route path="/sign-up" exact component={PublicSignUp}></Route>
              <Route
                path="/sign-up/callback"
                exact
                component={PublicSignUp}
              ></Route>
              <Route path="/" component={UserRoutes}></Route>
            </Switch>
          </CartContext.Provider>
        </Switch>
      </AuthContext.Provider>
    )
  );
}
