import React, { useEffect, useState } from "react";
import formJSON from "../../formConfigs/CreateField.json";
import Modal from "../../components/Modal";
import {
  CButton,
  CButtonGroup,
  CCardBody,
  CCard,
  CForm,
  CFormLabel,
  CCardHeader,
} from "@coreui/react";
import Element from "../../components/Element";
import { useHistory } from "react-router";
import axios from "axios";

export default function CreateField(props) {
  const [validated, setValidated] = useState(false);
  const [field, setField] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [elements, setElements] = useState(null);
  const { fields } = elements ?? {};

  //For Modal Functions
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/fields");
        break;
      case "Yes":
        deleteField();
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  function confirmDeleteField() {
    setModalMode("Confirm");
    displayModal("Confirm delete field: " + field.field_name);
  }

  const deleteField = () => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/fields/" + field._id)
      .then((res) => {
        setModalMode("Info");
        displayModal(res.data.message);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setElements(formJSON[0]);
    if (props.action === "edit") {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/fields/" +
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ]
        )
        .then((res) => {
          console.log(res.data);
          setField(res.data);
          //setValue("eventName", res.data.eventName);
          props.updateTitle("Edit Field: " + res.data.field_name);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      props.updateTitle("Create Field");
      setIsLoading(false);
    }
  }, []);

  //Submit Event
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const data = new FormData(e.target);
      var object = {};

      data.forEach(function (value, key) {
        if (key.toLowerCase().includes("date")) {
          object[key] = new Date(value);
        } else if (key == "eventCategories") {
          if (value == "none") {
            object[key] = [];
          } else {
            object[key] = value;
          }
        } else if (key == "field_values" || key == "field_file_types") {
          console.log(object[key]);
          console.log(value);
          if (object[key] != undefined && object[key] != null) {
            console.log(key + " : " + value);
            let existingVal = object[key];
            existingVal.push(value);
            object[key] = existingVal;
          } else if (value == "") {
            object[key] = [];
          } else {
            object[key] = [value];
          }
        } else {
          object[key] = value;
        }
      });
      if (props.action === "edit") {
        axios
          .put(process.env.REACT_APP_API_URL + "/fields/" + field._id, object)
          .then((res) => {
            if (res.status === 200) {
              displayModal(
                "Successfully updated field: " + res.data.field_name
              );
            }
            if (res.status != 200) {
              displayModal("Failed to update field: " + field.field_name);
            }
          })
          .catch((err) => {
            displayModal("Failed to update field: " + field.field_name);
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_URL + "/fields", object)
          .then((res) => {
            if (res.status === 200) {
              setModalMode("Info");
              if (res.data.length > 1) {
                displayModal(
                  "Successfully created event: " + res.data[0].field_name
                );
              } else {
                displayModal(
                  "Successfully created event: " + res.data.field_name
                );
              }
            } else {
              setModalMode("Info");
              displayModal("Failed to create field: " + field.field_name);
            }
          })
          .catch((err) => {
            displayModal("Failed to create field: " + field.field_name);
          });
      }
    }
    setValidated(true);
  };

  if (props.action === "edit") {
    return (
      !isLoading &&
      field && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field_, i) => {
                      if (field[field_.field_name]) {
                        if (i == 0) {
                          return (
                            <Element
                              key={i}
                              field={field_}
                              value={field[field_.field_name]}
                            />
                          );
                        } else {
                          return (
                            <Element
                              key={i}
                              field={field_}
                              value={field[field_.field_name]}
                              className="mt-3"
                            />
                          );
                        }
                      } else {
                        if (i == 0) {
                          return <Element key={i} field={field_} />;
                        } else {
                          return (
                            <Element key={i} field={field_} className="mt-3" />
                          );
                        }
                      }
                    })
                  : null}
                <CButtonGroup className="mt-3">
                  <CButton type="submit">Submit</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeleteField()}
                  >
                    Delete
                  </CButton>
                </CButtonGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </>
      )
    );
  } else {
    return (
      !isLoading && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field, i) => {
                      if (i == 0) {
                        return <Element key={i} field={field} />;
                      } else {
                        return (
                          <Element key={i} field={field} className="mt-3" />
                        );
                      }
                    })
                  : null}

                <CButtonGroup className="mt-3">
                  <CButton type="submit">Submit</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeleteField()}
                  >
                    Delete
                  </CButton>
                </CButtonGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </>
      )
    );
  }
}
