import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
//import { useSelector, useDispatch } from "react-redux";
// sidebar nav config
import webNavigation from "./_nav";
import "../assets/css/nav.css";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faClipboard,
  faCalendar,
  faUser,
  faStickyNote,
  faPowerOff,
  faPencilAlt,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, useHistory } from "react-router-dom";
import Calendar from "../pages/Calendar";
import Bookings from "../pages/Bookings";
import { AuthContext } from "../hooks/AuthContext";
import "../assets/css/style.css";

export default function Sidebar(props) {
  const history = useHistory();
  const [displayMoreNav, setDisplayMoreNav] = useState(false);
  let path = "";
  if (props.path) {
    path = props.path;
  }
  /*const show = useSelector((state) => state.sidebarShow);
	const webNavigationShow = useSelector((state) => state.sidebarNav);*/
  /*.sidenav---sidenav---_2tBP
	sidenav---expanded---1KdUL*/
  const authContext = useContext(AuthContext);
  const { token, user } = useContext(AuthContext);
  function logout() {
    if (authContext.logout()) {
      history.replace("/login");
      history.push("/login");
    }
  }

  const more = () => {
    if (!displayMoreNav) {
      setDisplayMoreNav(true);
    } else {
      setDisplayMoreNav(false);
    }
  };

  const MoreNav = () => {
    return (
      displayMoreNav && (
        <div className="more-nav">
          <NavLink className="link mobile-only" to="/memberships">
            <NavItem eventKey="charts">
              <NavIcon>
                <FontAwesomeIcon icon={faClipboard} />
              </NavIcon>
              <NavText>Memberships</NavText>
            </NavItem>
          </NavLink>

          <NavLink className="link mobile-only" to="/calendar">
            <NavItem eventKey="home">
              <NavIcon>
                <FontAwesomeIcon icon={faCalendar} />
              </NavIcon>
              <NavText>Calendar</NavText>
            </NavItem>
          </NavLink>

          <NavLink className="link mobile-only" to="/profile">
            <NavItem eventKey="home">
              <NavIcon>
                <FontAwesomeIcon icon={faUser} />
              </NavIcon>
              <NavText>Profile</NavText>
            </NavItem>
          </NavLink>
        </div>
      )
    );
  };

  //admin only routes
  if (user && user.accType == "Admin") {
    return (
      <>
        <SideNav onSelect={(selected) => {}}>
          <SideNav.Nav defaultSelected="home">
            <NavLink className="link" to={path} exact>
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faHome} />
                </NavIcon>
                <NavText>Home</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to={path + "/events"} exact>
              <NavItem eventKey="charts">
                <NavIcon>
                  <FontAwesomeIcon icon={faClipboard} />
                </NavIcon>
                <NavText>Events</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to={path + "/events/categories"}>
              <NavItem eventKey="charts">
                <NavIcon>
                  <FontAwesomeIcon icon={faClipboard} />
                </NavIcon>
                <NavText>Event Categories</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to={path + "/fields"}>
              <NavItem eventKey="charts">
                <NavIcon>
                  <FontAwesomeIcon icon={faClipboard} />
                </NavIcon>
                <NavText>Fields</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link non-mobile" to="/memberships">
              <NavItem eventKey="charts">
                <NavIcon>
                  <FontAwesomeIcon icon={faClipboard} />
                </NavIcon>
                <NavText>Memberships</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link non-mobile" to="/calendar">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faCalendar} />
                </NavIcon>
                <NavText>Calendar</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to="/bookings">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faStickyNote} />
                </NavIcon>
                <NavText>Bookings</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to="/transactions">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faStickyNote} />
                </NavIcon>
                <NavText>Transactions</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link non-mobile" to="/users">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faCalendar} />
                </NavIcon>
                <NavText>Users</NavText>
              </NavItem>
            </NavLink>

            <a className="link mobile-only" onClick={() => more()}>
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </NavIcon>
                <NavText>More</NavText>
              </NavItem>
            </a>

            <MoreNav />

            <div className="bottom-links">
              <NavLink className="link" to="/profile">
                <NavItem eventKey="home">
                  <NavIcon>
                    <FontAwesomeIcon icon={faUser} />
                  </NavIcon>
                  <NavText>Profile</NavText>
                </NavItem>
              </NavLink>

              <a className="link" onClick={() => logout()}>
                <NavItem eventKey="home">
                  <NavIcon>
                    <FontAwesomeIcon icon={faPowerOff} />
                  </NavIcon>
                  <NavText>Log Out</NavText>
                </NavItem>
              </a>
            </div>
          </SideNav.Nav>
        </SideNav>
      </>
    );
  }

  if (user && user.accType == "Guest") {
    return (
      <>
        <SideNav>
          <SideNav.Nav defaultSelected="home">
            <NavLink className="link" to={path} exact>
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faHome} />
                </NavIcon>
                <NavText>Home</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to={path + "/events"}>
              <NavItem eventKey="charts">
                <NavIcon>
                  <FontAwesomeIcon icon={faClipboard} />
                </NavIcon>
                <NavText>Events</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to="/bookings">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faStickyNote} />
                </NavIcon>
                <NavText>Bookings</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link" to="/calendar">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faCalendar} />
                </NavIcon>
                <NavText>Calendar</NavText>
              </NavItem>
            </NavLink>

            <NavLink className="link mobile-only" to="/profile">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faUser} />
                </NavIcon>
                <NavText>Profile</NavText>
              </NavItem>
            </NavLink>

            <div className="bottom-links">
              <NavLink className="link" to="/profile">
                <NavItem eventKey="home">
                  <NavIcon>
                    <FontAwesomeIcon icon={faUser} />
                  </NavIcon>
                  <NavText>Profile</NavText>
                </NavItem>
              </NavLink>

              <a className="link" onClick={() => logout()}>
                <NavItem eventKey="home">
                  <NavIcon>
                    <FontAwesomeIcon icon={faPowerOff} />
                  </NavIcon>
                  <NavText>Log Out</NavText>
                </NavItem>
              </a>
            </div>
          </SideNav.Nav>
        </SideNav>
      </>
    );
  }

  return (
    <>
      <SideNav onSelect={(selected) => {}}>
        <SideNav.Nav defaultSelected="home">
          <NavLink className="link" to={path} exact>
            <NavItem eventKey="home">
              <NavIcon>
                <FontAwesomeIcon icon={faHome} />
              </NavIcon>
              <NavText>Home</NavText>
            </NavItem>
          </NavLink>

          <NavLink className="link" to={path + "/events"}>
            <NavItem eventKey="charts">
              <NavIcon>
                <FontAwesomeIcon icon={faClipboard} />
              </NavIcon>
              <NavText>Events</NavText>
            </NavItem>
          </NavLink>

          <NavLink className="link" to="/bookings">
            <NavItem eventKey="home">
              <NavIcon>
                <FontAwesomeIcon icon={faStickyNote} />
              </NavIcon>
              <NavText>Bookings</NavText>
            </NavItem>
          </NavLink>

          <NavLink className="link" to="/calendar">
            <NavItem eventKey="home">
              <NavIcon>
                <FontAwesomeIcon icon={faCalendar} />
              </NavIcon>
              <NavText>Calendar</NavText>
            </NavItem>
          </NavLink>

          <NavLink className="link mobile-only" to="/profile">
            <NavItem eventKey="home">
              <NavIcon>
                <FontAwesomeIcon icon={faUser} />
              </NavIcon>
              <NavText>Profile</NavText>
            </NavItem>
          </NavLink>

          <div className="bottom-links">
            <NavLink className="link" to="/profile">
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faUser} />
                </NavIcon>
                <NavText>Profile</NavText>
              </NavItem>
            </NavLink>
            <a className="link" onClick={() => logout()}>
              <NavItem eventKey="home">
                <NavIcon>
                  <FontAwesomeIcon icon={faPowerOff} />
                </NavIcon>
                <NavText>Log Out</NavText>
              </NavItem>
            </a>
          </div>
        </SideNav.Nav>
      </SideNav>
    </>
  );
}
