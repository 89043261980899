import { useState, useEffect } from "react";
import axios from "axios";

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    //setUser(localStorage.getItem("user"));
  }, []);
  return {
    user,
    isLoading,
  };
}
