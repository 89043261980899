import React, { useEffect, useState, useContext } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CForm,
  CFormGroup,
  CFormText,
  CLabel,
  CCardHeader,
  CCol,
  CRow,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import BookingItem from "../components/BookingItem";
import UpcomingBookingItem from "../components/UpcomingBookingItem";
import "../assets/css/bookings.css";
import axios from "axios";
import { AuthContext } from "../hooks/AuthContext";
import Modal from "../components/Modal";
import { useHistory } from "react-router";

export default function Bookings(props) {
  const [bookings, setBookings] = useState([]);
  const history = useHistory();
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const [modalLink, setModalLink] = useState("");

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/bookings");
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    document.title = "Bookings";
    props.updateTitle("Bookings");
    if (user.accType == "Admin") {
      axios
        .get(process.env.REACT_APP_API_URL + "/bookings")
        .then((res) => {
          console.log(res.data);
          let bookings = res.data;
          setBookings(bookings);
          setFilteredBookings(bookings);
          setIsLoading(false);
        })
        .catch((err) => {});
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + "/users/" + user._id + "/bookings")
        .then((res) => {
          let bookings = res.data;
          setBookings(bookings);
          setFilteredBookings(bookings);
          setIsLoading(false);
        })
        .catch((err) => {});
    }
  }, []);

  function search(filter) {
    if (filter != "") {
      setFilteredBookings(
        filteredBookings.filter((booking) =>
          booking.events.forEach((event) => {
            if (event.eventName.toLowerCase().includes(filter.toLowerCase())) {
              return event;
            }
          })
        )
      );
    } else {
      setFilteredBookings(bookings);
    }
  }

  function exportToCsv() {
    axios
      .post(process.env.REACT_APP_API_URL + "/bookings/downloadCSV")
      .then((res) => {
        setModalMode("Link");
        setModalLink(res.data.message);
        displayModal("Download at: ");
      })
      .catch((err) => {
        console.log(err);
        setModalMode("Error");
        displayModal(err.message);
      });
  }

  /*function sort(filter) {
		setSortBy(filter);
		if (filter == "earliest") {
			setFilteredEvents(
				[...filteredBookings].sort((a, b) =>
					a.startDate > b.startDate ? 1 : -1
				)
			);
		} else if (filter == "latest") {
			setFilteredEvents(
				[...filteredBookings].sort((a, b) =>
					a.startDate < b.startDate ? 1 : -1
				)
			);
		} else {
		}
	}*/

  const ExportToCSV = () => {
    return (
      user.accType == "Admin" && (
        <CCardHeader>
          <CButton onClick={() => exportToCsv()}>Export to CSV</CButton>
        </CCardHeader>
      )
    );
  };

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          message={modalMessage}
          mode={modalMode}
          link={modalLink}
        ></Modal>
        <CCard>
          <ExportToCSV />
          <CCardBody>
            <CRow className="mb-3">
              <CCol lg={6} md={6}>
                <CFormInput
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => search(e.target.value)}
                />
              </CCol>
              <CCol lg={6} md={6}>
                <CFormSelect className="form-control">
                  <option value="Sort By">Sort By</option>
                  <option value="earliest">Earliest Date</option>
                  <option value="latest">Latest Date</option>
                </CFormSelect>
              </CCol>
            </CRow>
            {filteredBookings.map((booking) => {
              return (
                <UpcomingBookingItem key={booking._id} booking={booking} />
              );
            })}
          </CCardBody>
        </CCard>
      </>
    )
  );
}
