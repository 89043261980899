import React, { useState, useEffect } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCol,
  CFormFeedback,
  CFormSelect,
  CFormTextarea,
} from "@coreui/react";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import CreatableSelect from "react-select/creatable";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import Select from "react-select";
import axios from "axios";

const Element = ({
  field: {
    field_type,
    field_id,
    field_name,
    field_label,
    field_placeholder,
    field_value,
    field_options,
    field_mandatory,
    field_error_msg,
    field_options_url,
    field_file_types,
    field_file_max_individual_size,
    field_file_max_count,
  },
  className,
  value,
  waiverAndReleaseVisiblity,
  updateWaiverAndReleaseCheck,
  waiverAndReleaseCheck,
  setCheckedValues,
  disabled,
  formData,
}) => {
  const [checkedValue, setCheckedValue] = useState([]);
  const [validity, setValidity] = useState(false);
  const [dateValue_, onChange] = useState(
    value == "" || value == null || value == undefined
      ? new Date()
      : new Date(value)
  );
  const [value_, onValue_Change] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldBeChecked, setShouldBeChecked] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [fieldAPIOptions, setFieldAPIOptions] = useState([]);

  const onDateChangeHandler = (newValue) => {
    onChange(newValue);
    if (newValue == 0 && field_mandatory === "required") {
      setValidity(false);
      onValue_Change(newValue);
    } else if (newValue > 0 && field_mandatory === "required") {
      setValidity(true);
      onValue_Change(newValue);
    } else if (newValue >= 0 && field_mandatory != "required") {
      setValidity(true);
      onValue_Change(newValue);
    }
  };

  const onNumberChangeHandler = (newValue) => {
    onChange(newValue);
    if (newValue.target.value == 0 && field_mandatory === "required") {
      setValidity(false);
      onValue_Change(newValue.target.value);
    } else if (newValue.target.value > 0 && field_mandatory === "required") {
      setValidity(true);
      onValue_Change(newValue.target.value);
    } else if (newValue.target.value >= 0 && field_mandatory != "required") {
      setValidity(true);
      onValue_Change(newValue.target.value);
    }
  };

  const onTelChangeHandler = (newValue) => {
    onChange(newValue);
    var regex = new RegExp("\\+?\\d+");
    console.log(regex.test(newValue.target.value));
    if (!regex.test(newValue.target.value) && field_mandatory === "required") {
      setValidity(false);
      onValue_Change(newValue.target.value);
    } else {
      setValidity(true);
      onValue_Change(newValue.target.value);
    }
  };

  const onInputChangeHandler = (e) => {
    /*
    checked={
                      checkedValue != null
                        ? checkedValue.includes(option.option_label)
                          ? true
                          : null
                        : null
                    }*/
    setIsLoading(true);
    if (field_name === "status") {
      let valuesArr = checkedValue.slice();
      if (!checkedValue.includes(e.currentTarget.value)) {
        valuesArr.push(e.target.value);
        setCheckedValue(valuesArr);
      } else {
        let index = Array.prototype.indexOf(e.target.value);
        valuesArr.splice(index);
        setCheckedValue(valuesArr);
      }
      console.log(valuesArr);
      setCheckedValue(valuesArr);
      setCheckedValues(valuesArr);
    } else if (field_name == "waiverAndRelease") {
      let valuesArr = checkedValue.slice();

      if (!checkedValue.includes(e.currentTarget.value)) {
        valuesArr.push(e.target.value);
        setCheckedValue(valuesArr);
      } else {
        let index = Array.prototype.indexOf(e.target.value);
        valuesArr.splice(index);
        setCheckedValue(valuesArr);
      }
      setCheckedValue(valuesArr);
      updateWaiverAndReleaseCheck(valuesArr);
    } else {
      let valuesArr = checkedValue.slice();

      if (!checkedValue.includes(e.currentTarget.value)) {
        valuesArr.push(e.target.value);
        setCheckedValue(valuesArr);
      } else {
        let index = Array.prototype.indexOf(e.target.value);
        valuesArr.splice(index);
        setCheckedValue(valuesArr);
      }
      console.log(valuesArr);
      setCheckedValue(valuesArr);
    }
    setIsLoading(false);
  };

  const onCreatableSelectHandler = (newValue, actionMeta) => {
    console.log("Value Changed");
    setIsLoading(true);
    let newValues = [];
    newValue.forEach((val) => {
      newValues.push(val.value);
    });
    console.log(newValues);
    if (newValues.length > 0) {
      setFieldOptions(newValues);
      console.groupEnd();
    } else {
      setFieldOptions(null);
    }
    setIsLoading(false);
  };

  const callAPIForOptions = () => {
    setIsLoading(true);
    if ((field_options_url != undefined) & (field_options_url != "")) {
      axios
        .get(process.env.REACT_APP_API_URL + field_options_url)
        .then((res) => {
          console.log(res.data);
          let field_options_mod = [];
          res.data.forEach((option) => {
            field_options_mod.push({
              label: option.field_name,
              value: option._id,
            });
          });
          setFieldAPIOptions(field_options_mod);
        })
        .catch((err) => {});
    }
    setIsLoading(false);
  };

  //For File
  const [files, setFiles] = useState([]);
  const updateFiles = (incomingFiles) => {
    setFiles(incomingFiles);
    onValue_Change(incomingFiles);
    formData(field_label, incomingFiles);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!value && field_type === "date") {
      onChange(new Date());
    } else if (value && field_type === "date") {
      onChange(new Date(value));
    }
    if (field_type === "number" && field_mandatory === "required") {
      if (value > 0) {
        setValidity(true);
      } else {
        setValidity(false);
      }
      if (value) {
        onValue_Change(value);
      } else {
        onValue_Change(0);
      }
    }
    if (field_type === "number" && field_mandatory != "required") {
      if (value >= 0) {
        setValidity(true);
      } else if (!value) {
        setValidity(true);
      } else {
        setValidity(false);
      }
      if (value) {
        onValue_Change(value);
      } else {
        onValue_Change(0);
      }
    }
    if (field_type === "checkbox") {
      if (value != undefined) {
        setCheckedValue(value);
      }
      if (field_name === "waiverAndRelease") {
        if (
          waiverAndReleaseCheck ==
          "I acknowledge, consent and agree to the Waiver and Release form (Annex A)"
        ) {
          console.log(value);
          setVisibility(true);
        }
      }
    }

    if (field_type === "tel" && field_mandatory === "required") {
      if (value) {
        onValue_Change(value);
        var regex = new RegExp("\\+?\\d+");
        if (regex.test(value) && field_mandatory === "required") {
          setValidity(true);
        }
      } else {
        onValue_Change();
      }
    }

    if (
      field_type == "multiple-select" ||
      field_type == "multiple-select-non-create"
    ) {
      console.log(value);
      console.log(field_options);
      if (value != undefined && Array.isArray(value)) {
        //Check if any of the values match the hardcoded options
        let field_options_mod = [];
        value.forEach((option) => {
          if (field_options != undefined) {
            let foundMatchingOption = field_options.find(
              (el) => el.value === option
            );
            if (foundMatchingOption) {
              console.log(foundMatchingOption);
              field_options_mod.push({
                label: foundMatchingOption.label,
                value: foundMatchingOption.value,
              });
            }
          }
        });
        setFieldOptions(field_options_mod);

        if (field_options_mod.length == 0) {
          let field_options_mod = [];
          value.forEach((option) => {
            if (option.hasOwnProperty("_id")) {
              field_options_mod.push({
                label: option.field_name,
                value: option._id,
              });
            } else {
              field_options_mod.push({
                label: option,
                value: option,
              });
            }
          });
          setFieldOptions(field_options_mod);
        }
      } else if ((field_options_url != undefined) & (field_options_url != "")) {
        axios
          .get(process.env.REACT_APP_API_URL + field_options_url)
          .then((res) => {
            console.log(res.data);
            let field_options_mod = [];
            res.data.forEach((option) => {
              field_options_mod.push({
                label: option.field_name,
                value: option._id,
              });
            });
            setFieldAPIOptions(field_options_mod);
          })
          .catch((err) => {});
      } else {
        let field_options_mod = [];
        if (Array.isArray(value)) {
          value.forEach((option) => {
            field_options_mod.push({
              label: option,
              value: option,
            });
          });
        } else {
          field_options_mod.push({
            label: value,
            value: value,
          });
          console.log(field_options_mod);
        }
      }
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (field_name === "waiverAndRelease") {
      setVisibility(waiverAndReleaseVisiblity);
    }
    setIsLoading(false);
  }, []);

  switch (field_type) {
    case "text":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <CFormInput
              id={field_id}
              placeholder={field_placeholder}
              value={field_value}
              required={field_mandatory}
              name={field_name}
              defaultValue={value}
              disabled={disabled}
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "password":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <CFormInput
              id={field_id}
              type="password"
              placeholder={field_placeholder}
              value={field_value}
              required={field_mandatory}
              name={field_name}
              defaultValue={value}
              disabled={disabled}
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "email":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <CFormInput
              id={field_id}
              placeholder={field_placeholder}
              value={field_value}
              required={field_mandatory}
              name={field_name}
              defaultValue={value}
              disabled={disabled}
              type="email"
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "textarea":
      return (
        !isLoading && (
          <div className={className}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <CFormTextarea
              id={field_id}
              placeholder={field_placeholder}
              value={field_value}
              required={field_mandatory}
              name={field_name}
              disabled={disabled}
              defaultValue={value}
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "select":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <CFormSelect
              field_id={field_id}
              className="form-control"
              required={field_mandatory}
              name={field_name}
              disabled={disabled}
              defaultValue={value}
            >
              {field_options.length > 0 &&
                field_options.map((option, i) => (
                  <option
                    value={
                      option.option_value != undefined &&
                      option.option_value != ""
                        ? option.option_value
                        : option.option_label
                    }
                    key={i}
                  >
                    {option.option_label}
                  </option>
                ))}
            </CFormSelect>
          </div>
        )
      );
    case "checkbox":
      if (field_name == "status") {
        return (
          !isLoading && (
            <div className={className} key={field_id}>
              <CFormLabel htmlFor="validationCustom01">
                <strong>{field_label}</strong>
              </CFormLabel>

              {field_options.length > 0 &&
                field_options.map((option, i) => {
                  if (
                    checkedValue != undefined &&
                    checkedValue.includes(option.option_label)
                  ) {
                    return (
                      !isLoading && (
                        <CInputGroup>
                          <label>{option.option_label}</label>
                          <input
                            id={option.option_label}
                            value={option.option_label}
                            type="checkbox"
                            disabled={disabled}
                            checked={true}
                            name={field_name}
                            onChange={onInputChangeHandler}
                          />
                        </CInputGroup>
                      )
                    );
                  } else {
                    return (
                      !isLoading && (
                        <CInputGroup>
                          <label>{option.option_label}</label>
                          <input
                            id={option.option_label}
                            value={option.option_label}
                            name={field_name}
                            type="checkbox"
                            disabled={disabled}
                            onChange={onInputChangeHandler}
                          />
                        </CInputGroup>
                      )
                    );
                  }
                })}
              <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
            </div>
          )
        );
      } else {
        return (
          !isLoading && (
            <div
              className={className}
              style={{ visibility: visibility == true ? "visible" : "hidden" }}
              key={waiverAndReleaseVisiblity}
            >
              <CFormLabel htmlFor="validationCustom01">
                <strong>{field_label}</strong>
              </CFormLabel>

              {field_options != undefined &&
                field_options.length > 0 &&
                field_options.map((option, i) => {
                  if (
                    checkedValue != undefined &&
                    checkedValue.includes(option.option_label)
                  ) {
                    return (
                      !isLoading && (
                        <CInputGroup className="custom-input-group">
                          <input
                            id={option.option_label}
                            value={option.option_label}
                            type="checkbox"
                            name={field_name}
                            disabled={disabled}
                            checked={true}
                            onChange={onInputChangeHandler}
                          />
                          <label>{option.option_label}</label>
                        </CInputGroup>
                      )
                    );
                  } else {
                    return (
                      !isLoading && (
                        <CInputGroup className="custom-input-group">
                          <input
                            id={option.option_label}
                            value={option.option_label}
                            name={field_name}
                            type="checkbox"
                            disabled={disabled}
                            onChange={onInputChangeHandler}
                          />
                          <label>{option.option_label}</label>
                        </CInputGroup>
                      )
                    );
                  }
                })}
              <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
            </div>
          )
        );
      }

    case "date":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <DateTimePicker
              className="form-control"
              dateFormat="dd/MM/yyyy hh:mm:ss a"
              name={field_name}
              value={dateValue_}
              disabled={disabled}
              onChange={onDateChangeHandler}
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "dateonly":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <DatePicker
              className="form-control"
              dateFormat="dd/MM/yyyy"
              name={field_name}
              disabled={disabled}
              value={dateValue_}
              onChange={onDateChangeHandler}
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "number":
      if (field_mandatory == "required") {
        return (
          !isLoading && (
            <div className={className} key={field_id}>
              <CFormLabel htmlFor="validationCustom01">
                <strong>{field_label}</strong>
              </CFormLabel>
              <CFormInput
                type="number"
                required={field_mandatory}
                invalid={!validity}
                valid={validity}
                name={field_name}
                disabled={disabled}
                defaultValue={value_}
                min="1"
                onChange={onNumberChangeHandler}
              />
              <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
            </div>
          )
        );
      } else {
        return (
          !isLoading && (
            <div className={className} key={field_id}>
              <CFormLabel htmlFor="validationCustom01">
                <strong>{field_label}</strong>
              </CFormLabel>
              <CFormInput
                type="number"
                invalid={!validity}
                valid={validity}
                name={field_name}
                disabled={disabled}
                value={value_}
                onChange={onNumberChangeHandler}
              />
              <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
            </div>
          )
        );
      }
    case "tel":
      if (field_mandatory == "required") {
        return (
          !isLoading && (
            <div className={className} key={field_id}>
              <CFormLabel htmlFor="validationCustom01">
                <strong>{field_label}</strong>
              </CFormLabel>
              <CFormInput
                type="tel"
                required={field_mandatory}
                name={field_name}
                invalid={!validity}
                valid={validity}
                disabled={disabled}
                defaultValue={value_}
                onChange={onTelChangeHandler}
              />
              <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
            </div>
          )
        );
      }
    case "multiple-select":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <CreatableSelect
              required={field_mandatory}
              name={field_name}
              disabled={disabled}
              isMulti
              defaultValue={
                fieldOptions != null && fieldOptions.length > 0
                  ? fieldOptions
                  : []
              }
              options={
                fieldAPIOptions != null && fieldAPIOptions.length > 0
                  ? fieldAPIOptions
                  : []
              }
              onChange={onCreatableSelectHandler}
              onFocus={callAPIForOptions}
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "multiple-select-non-create":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <Select
              required={field_mandatory}
              name={field_name}
              disabled={disabled}
              isMulti
              defaultValue={
                fieldOptions != null && fieldOptions.length > 0
                  ? fieldOptions
                  : []
              }
              options={
                fieldAPIOptions != null && fieldAPIOptions.length > 0
                  ? fieldAPIOptions
                  : field_options != null && field_options.length > 0
                  ? field_options
                  : []
              }
              onChange={onCreatableSelectHandler}
              onFocus={callAPIForOptions}
            />
            <CFormFeedback invalid>{field_error_msg}</CFormFeedback>
          </div>
        )
      );
    case "file":
      return (
        !isLoading && (
          <div className={className} key={field_id}>
            <CFormLabel htmlFor="validationCustom01">
              <strong>{field_label}</strong>
            </CFormLabel>
            <Dropzone
              onChange={updateFiles}
              value={files}
              maxFiles={field_file_max_count != 0 ? field_file_max_count : 1}
              maxFileSize={
                field_file_max_individual_size != 0
                  ? field_file_max_individual_size
                  : 5
              }
              accept={
                field_file_types != undefined && field_file_types != ""
                  ? field_file_types.join(",")
                  : ""
              }
            >
              {files.map((file) => (
                <FileItem {...file} preview />
              ))}
            </Dropzone>
          </div>
        )
      );
    default:
      return null;
  }
};

export default Element;
