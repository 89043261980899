import {
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CContainer,
  CRow,
  CCol,
  CButton,
  CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import React, { useState, useContext } from "react";
import { AuthContext } from "../hooks/AuthContext";
import { useHistory } from "react-router";
import Modal from "../components/Modal";

export default function ChangePassword() {
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const { user, token } = useContext(AuthContext);
  const authContext = useContext(AuthContext);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmNewPasswordValid, setConfirmNewPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");

  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState(
    "Please confirm your password"
  );

  const updateNewPassword = (value) => {
    setNewPassword(value);
    if (!regex.test(value)) {
      setIsLoading(true);
      setNewPasswordValid(false);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setNewPasswordValid(true);
      setIsLoading(false);
    }
  };

  const updateConfirmNewPassword = (value) => {
    setConfirmNewPassword(value);
    if (newPassword != value) {
      setIsLoading(true);
      setConfirmNewPasswordValid(false);
      setConfirmPasswordErrorMsg("Passwords do not match");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setConfirmNewPasswordValid(true);
      setIsLoading(false);
    }
  };

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const logout = () => {
    authContext.logout();
    history.replace("/login");
  };

  const regex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
  );

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (newPassword != confirmNewPassword) {
      setIsLoading(true);
      alert("New passwords do not match");
      e.preventDefault();
      e.stopPropagation();
      setNewPasswordValid(false);
      setConfirmNewPasswordValid(false);
      setIsLoading(false);
    } else if (!regex.test(newPassword)) {
      setIsLoading(true);
      alert(regex.test(newPassword));
      e.preventDefault();
      e.stopPropagation();
      setNewPasswordValid(false);
      setConfirmNewPasswordValid(false);
      setIsLoading(false);
    } else {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        setIsLoading(true);
        e.preventDefault();
        setNewPasswordValid(true);
        setConfirmNewPasswordValid(true);

        let email = user.email;
        if (!user.isActive) {
          axios
            .post(process.env.REACT_APP_API_URL + "/activate", {
              email,
              oldPassword,
              newPassword,
            })
            .then((res) => {
              console.log(res.data);
              authContext.logout();
              localStorage.removeItem("userDataKey");
              setModalMode("Info");
              alert("Successfully updated password. Please re login.");
              history.replace("/login");
              setIsLoading(false);
            })
            .catch((err) => {
              setModalMode("Error");
              displayModal(err.response.data.message);
              setIsLoading(false);
            });
        } else {
          axios.defaults.headers.common["Authorization"] = token;
          setEmail(user.email);
          axios
            .post(
              process.env.REACT_APP_API_URL +
                "/users/" +
                user._id +
                "/change-password",
              {
                email,
                oldPassword,
                newPassword,
              }
            )
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data);
                authContext.logout();
                localStorage.removeItem("userDataKey");
                setModalMode("Info");
                alert("Successfully updated password. Please re login.");
                history.replace("/login");
                setIsLoading(false);
              }
            })
            .catch((err) => {
              setModalMode("Error");
              displayModal(err.response.data.message);
              setIsLoading(false);
            });
        }
      }
    }
    setValidated(true);
  };

  const history = useHistory();

  const LogOutButton = () => {
    if (user && !user.isActive) {
      return (
        <CButton color="danger" className="mt-3" onClick={() => logout()}>
          Logout
        </CButton>
      );
    }
    return null;
  };

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={() => hideModal(modalMode)}
          title={modalTitle}
          message={modalMessage}
          mode={modalMode}
        ></Modal>
        <CContainer>
          <CCard>
            <CCardBody>
              <CRow>
                <CCol lg={12}>
                  <CCardHeader>Change Password</CCardHeader>
                  <CCardBody>
                    <CForm
                      className="needs-validation"
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div>
                        <CFormLabel htmlFor="validationCustom01">
                          Old Password
                        </CFormLabel>
                        <CFormInput
                          type="password"
                          name="oldPassword"
                          required
                          className="form-control"
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <CFormFeedback invalid>
                          Please enter your old password
                        </CFormFeedback>
                      </div>
                      <div>
                        <CFormLabel htmlFor="validationCustom01">
                          New Password
                        </CFormLabel>
                        <CFormInput
                          type="password"
                          name="newPassword"
                          required
                          className="form-control"
                          onChange={(e) => updateNewPassword(e.target.value)}
                          valid={newPasswordValid}
                          invalid={!newPasswordValid}
                        />
                        <CFormFeedback invalid>
                          Password must contain at least 1 uppercase, 1
                          lowercase, 1 number, 1 special character and min. 6
                          characters long
                        </CFormFeedback>
                      </div>
                      <div>
                        <CFormLabel htmlFor="validationCustom01">
                          Confirm New Password
                        </CFormLabel>
                        <CFormInput
                          type="password"
                          name="confirmNewPassword"
                          required
                          className="form-control"
                          onChange={(e) =>
                            updateConfirmNewPassword(e.target.value)
                          }
                          valid={confirmNewPasswordValid}
                          invalid={!confirmNewPasswordValid}
                        />
                        <CFormFeedback invalid>
                          {confirmPasswordErrorMsg}
                        </CFormFeedback>
                      </div>
                      <CButton type="submit" className="mt-3">
                        Submit
                      </CButton>
                      <br />
                      <LogOutButton />
                    </CForm>
                  </CCardBody>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CContainer>
      </>
    )
  );
}
