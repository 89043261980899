import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../hooks/AuthContext";
import { CCard, CCardHeader, CCardBody, CButton, CBadge } from "@coreui/react";
import RenewMembership from "./RenewMembership";
import { useHistory } from "react-router";
import moment from "moment";
import Element from "../components/Element";
import formJSON from "../formConfigs/RenewMembership.json";

export default function Membership(props) {
  const authContext = useContext(AuthContext);
  const [membership, setMembership] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [elements, setElements] = useState(null);
  const { fields, page_label } = elements ?? {};
  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
    console.log(userDetails);
  }, [userDetails]);

  useEffect(() => {
    setIsLoading(true);
    setElements(formJSON[0]);
    if (authContext.user.accType != "Admin") {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/users/" +
            authContext.user._id +
            "/membership"
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.message != "This user has no active membership") {
            setMembership(res.data);
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  "/users/" +
                  authContext.user._id +
                  "/details"
              )
              .then((res) => {
                console.log(res.data);
                setUserDetails(res.data);
              })
              .catch((err) => {
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/users/" +
            props.user._id +
            "/membership"
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.message != "This user has no active membership") {
            setMembership(res.data);
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  "/users/" +
                  props.user._id +
                  "/details"
              )
              .then((res) => {
                console.log(res.data);
                setUserDetails(res.data);
              })
              .catch((err) => {
                setIsLoading(false);
              });
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, []);

  function renewMembership() {
    history.push("/membership/renew");
  }

  function RenewButton() {
    let currentDate = new Date();
    let memEndDate = new Date(membership.membershipEndDate);
    memEndDate.setDate(memEndDate.getDate() - 14);

    if (currentDate >= memEndDate) {
      return (
        !props.user && (
          <div>
            Click to renew your membership
            <br />
            <CButton className="btn-sis" onClick={() => renewMembership()}>
              Renew
            </CButton>
          </div>
        )
      );
    }
    return null;
  }

  if (membership) {
    return (
      !isLoading && (
        <div>
          <title>Membership</title>
          <CCard>
            <CCardBody>
              <CCardHeader>
                Membership Status{" "}
                <CBadge className="membership-status-badge badge-success">
                  Active
                </CBadge>
              </CCardHeader>
              <CCardBody>
                <strong>Membership: </strong>
                {membership.membershipTitle}
                <br />
                <strong>Membership Start Date: </strong>
                {moment(membership.membershipStartDate).format("DD MMMM YYYY")}
                <br />
                <strong>Membership End Date: </strong>
                {moment(membership.membershipEndDate).format("DD MMMM YYYY")}
                <br />
                <br />
                <RenewButton />
                <br />
              </CCardBody>
              <CCardBody>
                {fields
                  ? fields.map((field, i) => {
                      if (userDetails[field.field_name]) {
                        if (authContext.user.accType != "Admin") {
                          if (i == 0) {
                            return (
                              <Element
                                key={i}
                                field={field}
                                disabled={true}
                                value={userDetails[field.field_name]}
                              />
                            );
                          } else {
                            return (
                              <Element
                                key={i}
                                field={field}
                                disabled={true}
                                value={userDetails[field.field_name]}
                                className="mt-3"
                              />
                            );
                          }
                        } else {
                          if (i == 0) {
                            return (
                              <Element
                                key={i}
                                field={field}
                                value={userDetails[field.field_name]}
                              />
                            );
                          } else {
                            return (
                              <Element
                                key={i}
                                field={field}
                                value={userDetails[field.field_name]}
                                className="mt-3"
                              />
                            );
                          }
                        }
                      } else {
                        /*if (i == 0) {
                        return <Element key={i} field={field} />;
                      } else {
                        return (
                          <Element key={i} field={field} className="mt-3" />
                        );
                      }*/
                      }
                    })
                  : null}
              </CCardBody>
            </CCardBody>
          </CCard>
        </div>
      )
    );
  }
  return (
    !isLoading &&
    !props.user && (
      <>
        <title>Membership</title>
        <CCard>
          <CCardBody>
            <CCard>
              <CCardHeader>
                Membership Status
                <CBadge className="membership-status-badge badge-danger">
                  Inactive
                </CBadge>
              </CCardHeader>
              <CCardBody>
                There is no active membership for your account
                <br />
                Click to renew your membership
                <br />
                <CButton className="btn-sis" onClick={() => renewMembership()}>
                  Renew
                </CButton>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </>
    )
  );
}
