import { CCardHeader, CCard, CRow, CCol, CButton } from "@coreui/react";
import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarkerAlt,
  faDollarSign,
  faArrowDown,
  faArrowUp,
  faTag,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../hooks/CartContext";
import AdditionalUserInput from "./AdditionalUserInput";
import "../assets/css/cart.css";

export default function CartItem(props) {
  const cartItem = props.cartItem;
  const cartContext = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);

  function removeFromCart() {
    cartContext.removeFromCart(cartItem);
  }

  function showAdditionalInput() {}

  const AdditionaInputDisplay = () => {
    if (cartItem.hasOwnProperty("additionalFields")) {
      console.log(Object.keys(cartItem.additionalFields));
      return (
        <>
          <div class="kebab-menu" onClick={() => showAdditionalInput}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <CRow>
        <CCol className="cart-col" lg={3}>
          {cartItem.item}
        </CCol>
        <CCol className="cart-col" lg={3}>
          {cartItem.description}
        </CCol>
        <CCol className="cart-col" lg={3}>
          ${cartItem.price}
        </CCol>
        <CCol className="cart-col" lg={3}>
          <CRow>
            <CCol className="cart-col" lg={6}>
              <AdditionaInputDisplay className="mr-5" />
            </CCol>
            <CCol className="cart-col" lg={6}>
              <CButton className="btn-danger" onClick={() => removeFromCart()}>
                <FontAwesomeIcon icon={faTrash} />
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </>
  );
}
