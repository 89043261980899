import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import MainHeader from "../components/MainHeader";

import "../assets/css/calendar.css";
import { CCard, CCardBody } from "@coreui/react";
import axios from "axios";
import { useHistory } from "react-router";

export default function Calendar(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    props.updateTitle("Calendar");
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/events")
      .then((res) => {
        console.log(res.data);
        var calEvents = [];
        res.data.map((event, index) => {
          let alteredEvent = event;
          alteredEvent.id = event._id;
          alteredEvent.title = event.eventName;
          alteredEvent.start = event.startDate;
          alteredEvent.end = event.endDate;
          calEvents.push(alteredEvent);
        });
        console.log(calEvents);
        setCalendarEvents(calEvents);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, []);

  const history = useHistory();
  const handleEventClick = (e) => {
    history.push("/events/detail/" + e.event.id);
  };
  return (
    !isLoading && (
      <>
        <CCard>
          <CCardBody>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={calendarEvents}
              eventClick={(arg) => handleEventClick(arg)}
            />
          </CCardBody>
        </CCard>
      </>
    )
  );
}
