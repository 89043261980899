import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CButton,
  CLink,
  CFormLabel,
  CBadge,
} from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarkerAlt,
  faDollarSign,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { AuthContext } from "../hooks/AuthContext";
import { useHistory } from "react-router";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import "../assets/css/spinner.css";
import axios from "axios";

export default function BookingItem(props) {
  const [showDetails, setShowDetails] = useState(false);
  const history = useHistory();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const booking = props.booking;
  const authContext = useContext(AuthContext);

  //For Modal Functions
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/bookings");
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  function toggleShowDetails() {
    if (showDetails == true) {
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  }
  const UserName = () => {
    if (authContext.user.accType == "Admin") {
      return (
        <>
          <strong>User:</strong>{" "}
          {booking.user != null
            ? booking.user.firstName
            : "User does not exist"}{" "}
          {booking.user != null ? booking.user.lastName : ""}
          <br />
        </>
      );
    }
    return null;
  };

  useEffect(() => {}, []);

  const override = css`
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1000;
  `;
  let [color, setColor] = useState("#ffffff");
  const LoadingSpinner = () => {
    return (
      showLoadingSpinner && (
        <MoonLoader color={color} loading={true} css={override} />
      )
    );
  };

  const exportBookingDetails = () => {
    setShowLoadingSpinner(true);
    axios({
      url:
        process.env.REACT_APP_API_URL +
        "/bookings/" +
        booking._id +
        "/additionalFields", //your url
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          }),
          downloadUrl = "",
          filename = "",
          disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }

        blob = new Blob([response.data], {
          type: "application/zip",
          filename: filename,
        });
        downloadUrl = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setShowLoadingSpinner(false);
      })
      .catch((err) => {
        setModalMode("Info");
        displayModal("Error exporting booking details");
        setShowLoadingSpinner(false);
      });
  };

  const ExportButton = () => {
    return (
      <CCol lg={3} sm={3} className="text-align-right">
        <CButton onClick={() => exportBookingDetails()}>
          Export Booking Details
        </CButton>
      </CCol>
    );
  };

  return (
    booking.user && (
      <>
        <LoadingSpinner />
        <CCard className="mt-3 mb-3">
          <div className="booking-details-toggle row">
            <CCol className="booking-item-name" lg={5} sm={5}>
              <UserName />
              <strong>Events Booked</strong>
              {booking.events.map((event) => {
                return (
                  <CRow className="booking-event-item" key={event._id}>
                    <div className="booking-item-detail-col">
                      {event.eventName} |{" "}
                      {moment(event.startDate).format("DD/MM/YYYY hh:mm:ss a")}
                    </div>
                  </CRow>
                );
              })}
            </CCol>
            <CCol
              lg={2}
              sm={2}
              className="text-align-right"
              onClick={() => toggleShowDetails()}
            >
              Details
              {(showDetails && (
                <FontAwesomeIcon className="ml-3" icon={faArrowDown} />
              )) || <FontAwesomeIcon className="ml-3" icon={faArrowUp} />}
            </CCol>
            <CCol lg={2} sm={2} className="text-align-right">
              <a href={"/bookings/" + booking._id}>View More</a>
            </CCol>
            <ExportButton />
          </div>
          {showDetails && (
            <CCard className="mt-3">
              <CCardBody>
                <CRow className={"booking-item-details"}>
                  <CCol lg={12} className="booking-item-detail-col">
                    <strong>Invoice ID: </strong>
                    <a href="#" className="mt-1">
                      {booking.invoiceNo}
                    </a>
                    <strong>Invoice Link: </strong>
                    <a
                      href={booking.invoiceLink}
                      className="mt-1"
                      target="_blank"
                    >
                      {booking.invoiceLink}
                    </a>
                  </CCol>
                  {/*<CCol lg={3} className="booking-item-detail-col">
                      <strong>Status</strong>
                      {booking.status.toLowerCase() == "paid" && (
                        <CBadge className="badge-success badge-custom mt-1">
                          Paid
                        </CBadge>
                      )}
                      {booking.status.toLowerCase() == "pending" && (
                        <CBadge className="badge-warning badge-custom mt-1">
                          Pending
                        </CBadge>
                      )}
                      </CCol>*/}
                  <br />
                  <br />
                  <CCol lg={4} sm={4} className="booking-item-detail-col">
                    <strong>Booking ID:</strong> {booking._id}
                    <br />
                  </CCol>
                  <CCol lg={4} sm={4} className="">
                    <strong>Created On:</strong>{" "}
                    {moment(booking.createdAt).format("DD/MM/YYYY hh:mm:ss a")}
                    <br />
                  </CCol>
                  <CCol lg={3}>
                    <strong>Total Price</strong>
                    <div className="mt-1">${booking.price}</div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          )}
        </CCard>
      </>
    )
  );
}
