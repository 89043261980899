import React, { useContext, useEffect, useState } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import MainHeader from "../components/MainHeader";
import UpcomingBookingItem from "../components/UpcomingBookingItem";

import "../assets/css/bootstrap.min.css";
import axios from "axios";
import { AuthContext } from "../hooks/AuthContext";

export default function UserDashboard(props) {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    props.updateTitle("Dashboard");
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/users/" + user._id + "/bookings/3")
      .then((res) => {
        let bookings = res.data;
        console.log(bookings);
        setBookings(bookings);
        setIsLoading(false);
        //bookings/latest
        //bookings/filter/month
      });
  }, []);

  function getStatistics() {}

  return (
    !isLoading &&
    user && (
      <>
        <CCard>
          {/*<CCardFooter>
            <CRow className="text-center">
              <CCol md sm="12" className="mb-sm-2 mb-0">
                <div className="text-muted">Events Booked This Month</div>
                <strong>29.703 Users (40%)</strong>
                <CProgress
                  className="progress-xs mt-2"
                  precision={1}
                  color="success"
                  value={40}
                />
              </CCol>
              <CCol md sm="12" className="mb-sm-2 mb-0 d-md-down-none">
                <div className="text-muted">Unique</div>
                <strong>24.093 Users (20%)</strong>
                <CProgress
                  className="progress-xs mt-2"
                  precision={1}
                  color="info"
                  value={40}
                />
              </CCol>
              <CCol md sm="12" className="mb-sm-2 mb-0">
                <div className="text-muted">Pageviews</div>
                <strong>78.706 Views (60%)</strong>
                <CProgress
                  className="progress-xs mt-2"
                  precision={1}
                  color="warning"
                  value={40}
                />
              </CCol>
            </CRow>
          </CCardFooter>*/}
        </CCard>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol lg={8}>
                <h4>Upcoming Bookings</h4>
              </CCol>
            </CRow>
            {bookings.map((booking) => {
              return (
                <UpcomingBookingItem key={booking._id} booking={booking} />
              );
            })}
          </CCardBody>
        </CCard>
      </>
    )
  );
}
