import React, { useState, useEffect, useContext } from "react";

import formJSON from "../../formConfigs/CreateEvent.json";
import Element from "../../components/Element";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCol,
  CRow,
  CFormFeedback,
  CFormSelect,
} from "@coreui/react";
import axios from "axios";
import userEvent from "@testing-library/user-event";
import { AuthContext } from "../../hooks/AuthContext";
import { useForm } from "react-hook-form";
import Modal from "../../components/Modal";
import { useHistory } from "react-router";

export default function CreateEvent(props) {
  const [validated, setValidated] = useState(false);
  const [selectValue, setSelectValue] = useState("Please select a gender");
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  //Form Fields
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const history = useHistory();
  const [checkDeleteAllRecurring, setCheckDeleteAllRecurring] = useState(false);
  const [categories, setCategories] = useState([]);

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/events");
        break;
      case "Yes":
        if (checkDeleteAllRecurring) {
          deleteAllRecurringEvents();
        } else {
          deleteEvent();
        }
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const data = new FormData(e.target);
      var object = {};
      data.forEach(function (value, key) {
        if (
          key.toLowerCase().includes("date") &&
          !key.toLowerCase().includes("enabled")
        ) {
          object[key] = new Date(value);
        } else if (key == "eventCategories") {
          if (value == "none") {
            object[key] = [];
          } else {
            object[key] = value;
          }
        } else {
          object[key] = value;
        }
      });
      console.log(object);
      if (props.action === "edit") {
        axios
          .put(process.env.REACT_APP_API_URL + "/events/" + event._id, object)
          .then((res) => {
            if (res.status === 200) {
              displayModal("Successfully updated event: " + res.data.eventName);
            }
            if (res.status != 200) {
              displayModal("Failed to update event: " + event.eventName);
            }
          })
          .catch((err) => {
            displayModal("Failed to update event: " + event.eventName);
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_URL + "/events", object)
          .then((res) => {
            if (res.status === 200) {
              setModalMode("Info");
              if (res.data.length > 1) {
                displayModal(
                  "Successfully created event: " + res.data[0].eventName
                );
              } else {
                displayModal(
                  "Successfully created event: " + res.data.eventName
                );
              }
            } else {
              setModalMode("Info");
              displayModal("Failed to create event: " + event.eventName);
            }
          })
          .catch((err) => {
            displayModal("Failed to create event: " + event.eventName);
          });
      }
    }
    setValidated(true);
  };

  const deleteEvent = (e) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/events/delete/" + event._id, {
        allRecurring: false,
      })
      .then((res) => {
        if (res.status === 200) {
          setModalMode("Info");
          displayModal("Successfully deleted event: " + event.eventName);
        }
        if (res.status != 200) {
          displayModal("Failed to delete event: " + event.eventName);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteAllRecurringEvents = (e) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/events/delete/" + event._id, {
        allRecurring: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setModalMode("Info");
          displayModal("Successfully deleted event: " + event.eventName);
        }
        if (res.status != 200) {
          displayModal("Failed to delete event: " + event.eventName);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function confirmDeleteEvent() {
    setModalMode("Confirm");
    displayModal("Confirm delete event: " + event.eventName);
    setCheckDeleteAllRecurring(false);
  }

  function confirmDeleteRecurringEvents() {
    setModalMode("Confirm");
    displayModal("Confirm delete all recurring events for: " + event.eventName);
    setCheckDeleteAllRecurring(true);
  }

  const [elements, setElements] = useState(null);
  useEffect(() => {
    setElements(formJSON[0]);
    if (props.action === "edit") {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/events/" +
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ]
        )
        .then((res) => {
          console.log(res.data);
          let event = res.data;
          setEvent(res.data);
          setValue("eventName", res.data.eventName);
          props.updateTitle("Edit Event: " + res.data.eventName);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(process.env.REACT_APP_API_URL + "/events/categories")
        .then((res) => {
          console.log(res.data);
          setCategories(res.data);
          setIsLoading(false);
        });
    } else {
      props.updateTitle("Create Event");
      axios
        .get(process.env.REACT_APP_API_URL + "/events/categories")
        .then((res) => {
          console.log(res.data);
          setCategories(res.data);
          setIsLoading(false);
        });
      setIsLoading(false);
    }
  }, []);

  const { fields, page_label } = elements ?? {};

  const DeleteRecurringButton = () => {
    if (
      event != null &&
      event.hasOwnProperty("repeat") &&
      event.repeat != "Never"
    ) {
      return (
        <CButton
          className="btn-danger ml-3"
          onClick={() => confirmDeleteRecurringEvents()}
        >
          Delete All Recurring Events
        </CButton>
      );
    } else {
      return null;
    }
  };

  /** Listener for all form fields **/
  function handleEventNameChange(e) {
    this.setState({ email: e.target.value });
  }

  //Event Categories
  const EventCategoriesSelect = () => {
    if (props.action == "edit") {
      return (
        !isLoading && [
          <option value="none">None</option>,
          categories.map((category, index) => {
            if (
              event.eventCategories != undefined &&
              event.eventCategories.some((elem) => {
                return JSON.stringify(category) === JSON.stringify(elem);
              })
            ) {
              return (
                <option key={category._id} value={category._id} selected>
                  {category.name}
                </option>
              );
            } else {
              return (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              );
            }
          }),
        ]
      );
    } else {
      return (
        !isLoading && [
          <option value="none">None</option>,
          categories.map((category, index) => {
            return (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            );
          }),
        ]
      );
    }
  };

  if (props.action === "edit") {
    return (
      !isLoading &&
      event && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field, i) => {
                      if (event[field.field_name]) {
                        if (i == 0) {
                          return (
                            <Element
                              key={i}
                              field={field}
                              value={event[field.field_name]}
                            />
                          );
                        } else {
                          return (
                            <Element
                              key={i}
                              field={field}
                              value={event[field.field_name]}
                              className="mt-3"
                            />
                          );
                        }
                      } else {
                        if (i == 0) {
                          return <Element key={i} field={field} />;
                        } else {
                          return (
                            <Element key={i} field={field} className="mt-3" />
                          );
                        }
                      }
                    })
                  : null}
                <CFormLabel className="mt-3">
                  <strong>
                    Event Category (determines what fields will be shown to the
                    user for input)
                  </strong>
                </CFormLabel>
                <CFormSelect className="form-control" name="eventCategories">
                  <EventCategoriesSelect />
                </CFormSelect>

                <CButtonGroup className="mt-3">
                  <CButton type="submit">Submit</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeleteEvent()}
                  >
                    Delete
                  </CButton>
                  <DeleteRecurringButton />
                </CButtonGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </>
      )
    );
  } else {
    return (
      !isLoading && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field, i) => {
                      if (i == 0) {
                        return <Element key={i} field={field} />;
                      } else {
                        return (
                          <Element key={i} field={field} className="mt-3" />
                        );
                      }
                    })
                  : null}
                <CFormLabel className="mt-3">
                  <strong>
                    Event Category (determines what fields will be shown to the
                    user for input)
                  </strong>
                </CFormLabel>
                <CFormSelect className="form-control" name="eventCategories">
                  <EventCategoriesSelect />
                </CFormSelect>

                <CButtonGroup className="mt-3">
                  <CButton type="submit">Submit</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeleteEvent()}
                  >
                    Delete
                  </CButton>
                  <DeleteRecurringButton />
                </CButtonGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </>
      )
    );
  }
}
