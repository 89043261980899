import React, { useContext, useEffect, useState } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
  CFormInput,
  CBadge,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Redirect, useHistory } from "react-router-dom";
import { CartContext } from "../hooks/CartContext";
import { AuthContext } from "../hooks/AuthContext";

export default function MainHeader(props) {
  const { cart, getCart } = useContext(CartContext);
  const { user } = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const [cartLength, setCartLength] = useState(0);
  const history = useHistory();
  document.title = props.title;
  useEffect(() => {
    setHeaderTitle(props.title);
  }, [props.title]);

  function viewCart() {
    history.push("/cart");
  }

  const [headerTitle, setHeaderTitle] = useState(props.title);
  if (cart && user.accType != "Admin") {
    return (
      <div>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol xs={8}>
                <h4 id="traffic" className="card-title mb-0">
                  {headerTitle}
                </h4>
              </CCol>
              {/*<CCol lg={3}>
                <CFormInput type="text" placeholder="Search.." />
    </CCol>*/}
              <CCol xs={4} className="text-right">
                <CButtonGroup>
                  <CartContext.Consumer>
                    {(cart) => (
                      <CButton className="btn-cart" onClick={viewCart}>
                        Cart
                        <CBadge className="ml-2">{cart.cart.length}</CBadge>
                      </CButton>
                    )}
                  </CartContext.Consumer>
                </CButtonGroup>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    );
  } else {
    return (
      <div>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol lg={9}>
                <h4 id="traffic" className="card-title mb-0">
                  {headerTitle}
                </h4>
              </CCol>
              {/*<CCol lg={3}>
                <CFormInput type="text" placeholder="Search.." />
    </CCol>*/}
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    );
  }
}
