import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CButton,
  CLink,
  CFormLabel,
  CBadge,
} from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarkerAlt,
  faDollarSign,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { AuthContext } from "../hooks/AuthContext";

export default function BookingItem(props) {
  const [showDetails, setShowDetails] = useState(false);
  const authContext = useContext(AuthContext);

  const booking = props.booking;

  function toggleShowDetails() {
    if (showDetails == true) {
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  }

  const UserName = () => {
    if (authContext.user.accType == "Admin") {
      return (
        <>
          <strong>User:</strong> {booking.user.firstName}{" "}
          {booking.user.lastName}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <CCard className="mt-3 mb-3">
        <CCardBody>
          <CLink
            onClick={() => toggleShowDetails()}
            className="booking-details-toggle"
          >
            <CRow className="booking-item-name">
              <CCol lg={9} sm={9} className="flex-horizontal-center">
                <h6 className="mb-0">
                  <strong>Booking ID:</strong> {booking._id} <br />
                  <strong>Created On:</strong>{" "}
                  {moment(booking.createdAt).format("DD/MM/YYYY hh:mm:ss a")}{" "}
                  <br />
                  <UserName />
                </h6>
              </CCol>
              <CCol lg={3} sm={3} className="text-align-right">
                <CLink>
                  Details
                  {(showDetails && (
                    <FontAwesomeIcon className="ml-3" icon={faArrowDown} />
                  )) || <FontAwesomeIcon className="ml-3" icon={faArrowUp} />}
                </CLink>
              </CCol>
            </CRow>
          </CLink>
          {showDetails && (
            <CCard className="mt-3">
              <CCardBody>
                <CRow className={"booking-item-details"}>
                  <CCol lg={12} className="booking-item-detail-col">
                    <strong>Invoice ID: </strong>
                    <a href="#" className="mt-1">
                      {booking.invoiceNo}
                    </a>
                    <strong>Invoice Link: </strong>
                    <a
                      href={booking.invoiceLink}
                      className="mt-1"
                      target="_blank"
                    >
                      {booking.invoiceLink}
                    </a>
                  </CCol>
                  {/*<CCol lg={3} className="booking-item-detail-col">
                    <strong>Status</strong>
                    {booking.status.toLowerCase() == "paid" && (
                      <CBadge className="badge-success badge-custom mt-1">
                        Paid
                      </CBadge>
                    )}
                    {booking.status.toLowerCase() == "pending" && (
                      <CBadge className="badge-warning badge-custom mt-1">
                        Pending
                      </CBadge>
                    )}
                    </CCol>*/}
                  <CCol lg={3}>
                    <strong>Total Price</strong>
                    <div className="mt-1">${booking.price}</div>
                  </CCol>
                </CRow>
                <strong>Events Booked</strong>
                {booking.events.map((event) => {
                  return (
                    <CRow className="booking-event-item" key={event._id}>
                      <CCol lg={8} sm={8} className="booking-item-detail-col">
                        <a
                          href={"/events/detail/" + event._id}
                          className="mt-1"
                        >
                          {event.eventName}
                        </a>
                      </CCol>
                      <CCol lg={4} sm={4}>
                        ${event.price}
                      </CCol>
                    </CRow>
                  );
                })}
              </CCardBody>
            </CCard>
          )}
        </CCardBody>
      </CCard>
    </>
  );
}
