import React, { useState, useEffect, useContext } from "react";

import formJSON from "../../formConfigs/CreateUser.json";
import Element from "../../components/Element";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCol,
  CRow,
  CCardHeader,
  CFormFeedback,
  CFormSelect,
} from "@coreui/react";
import axios from "axios";
import userEvent from "@testing-library/user-event";
import { AuthContext } from "../../hooks/AuthContext";
import { useForm } from "react-hook-form";
import Modal from "../../components/Modal";
import { useHistory } from "react-router";
import generator from "generate-password";
import BookingItem from "../../components/BookingItem";
import Membership from "../Membership";

export default function CreateUser(props) {
  const [validated, setValidated] = useState(false);
  const [selectValue, setSelectValue] = useState("Please select a gender");
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState("");
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  //Form Fields
  const [user, setUser] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const history = useHistory();
  const generatePassword = () => {
    const pwd = generator.generate({
      length: 10,
      lowercase: true,
      uppercase: true,
      numbers: true,
    });
    setPassword(pwd);
  };
  const [generatedPasswordLink, setGeneratedPasswordLink] = useState("");

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/users");
        break;
      case "Yes":
        deleteUser();
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const data = new FormData(e.target);
      var object = {};
      object["password"] = password;
      data.forEach(function (value, key) {
        if (key.toLowerCase().includes("date")) {
          object[key] = new Date(value);
        } else {
          object[key] = value;
        }
      });
      object["email"] = object["email"].toLowerCase();
      if (props.action === "edit") {
        axios
          .put(process.env.REACT_APP_API_URL + "/users/" + user._id, object)
          .then((res) => {
            if (res.status === 200) {
              displayModal(
                "Successfully updated user: " +
                  res.data.firstName +
                  " " +
                  res.data.lastName
              );
            }
            if (res.status != 200) {
              displayModal("Failed to update user: " + user.eventName);
            }
          })
          .catch((err) => {
            displayModal("Failed to update user: " + user.eventName);
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_URL + "/register", object)
          .then((res) => {
            if (res.status === 200) {
              setModalMode("Info");
              displayModal(
                "Successfully created user: " +
                  res.data.firstName +
                  " " +
                  res.data.lastName
              );
            } else {
              setModalMode("Info");
              displayModal("Failed to create user because: " + res);
            }
          })
          .catch((err) => {
            setModalMode("Info");
            displayModal(
              "Failed to create user because: " + err.response.data.message
            );
          });
      }
    }
    setValidated(true);
  };

  const deleteUser = (e) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/users/" + user._id)
      .then((res) => {
        if (res.status === 200) {
          setModalMode("Info");
          displayModal(
            "Successfully deleted user: " + user.firstName + " " + user.lastName
          );
        }
        if (res.status != 200) {
          displayModal("Failed to delete user: " + user.eventName);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function confirmDeleteUser() {
    setModalMode("Confirm");
    displayModal(
      "Confirm delete user: " + user.firstName + " " + user.lastName
    );
  }

  const [elements, setElements] = useState(null);
  useEffect(() => {
    setElements(formJSON[0]);
    generatePassword();
    if (props.action === "edit") {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/users/" +
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ]
        )
        .then((res) => {
          console.log(res.data);
          setUser(res.data);
          if (props.hasOwnProperty("updateTitle")) {
            props.updateTitle(
              "Edit User: " + res.data.firstName + " " + res.data.lastName
            );
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(true);
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/users/" +
            window.location.href.split("/")[
              window.location.href.split("/").length - 1
            ] +
            "/bookings"
        )
        .then((res) => {
          let bookings = res.data;
          bookings.forEach((booking) => {
            booking.price = booking.price.$numberDecimal;
            booking.events.forEach((event) => {
              event.price = event.price.$numberDecimal;
            });
          });
          setBookings(bookings);
          setIsLoading(false);
        });
    } else {
      if (props.hasOwnProperty("updateTitle")) {
        props.updateTitle("Create User");
      }

      setIsLoading(false);
    }
  }, []);

  const { fields, page_label } = elements ?? {};

  /** Listener for all form fields **/
  function handleEventNameChange(e) {
    this.setState({ email: e.target.value });
  }

  function UserBookings() {}

  function UserDetails() {}

  function generateResetLink() {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/users/" + user._id + "/generate-reset"
      )
      .then((res) => {
        setGeneratedPasswordLink(
          process.env.REACT_APP_URL + "/reset-password/" + res.data.link
        );
      })
      .catch((err) => {
        console.log(err);
        setModalMode("Error");
        displayModal(err.response.data.message);
      });
  }

  const UserMembershipComponent = () => {
    return <Membership user={user} className="mt-5" />;
  };

  const UserBookingComponent = () => {
    if (bookings && bookings.length > 0) {
      return bookings.map((booking) => {
        return <BookingItem key={booking._id} booking={booking} />;
      });
    }
    return null;
  };

  const GeneratePasswordResetLink = () => {
    if (user) {
      return (
        <div className="mt-3">
          <CFormLabel>
            <strong>
              Generate Password Reset Link <br />
            </strong>
            This link can be generated to reset a user's password if they have
            forgotten. The link is valid for 24H.
          </CFormLabel>
          <CInputGroup>
            <CFormInput
              type="text"
              defaultValue={generatedPasswordLink}
            ></CFormInput>
            <CButton onClick={() => generateResetLink()}>
              Generate Reset Link
            </CButton>
          </CInputGroup>
        </div>
      );
    }
    return null;
  };

  if (props.action === "edit") {
    return (
      !isLoading &&
      user && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field, i) => {
                      if (user[field.field_name]) {
                        if (i == 0) {
                          return (
                            <Element
                              key={i}
                              field={field}
                              value={user[field.field_name]}
                            />
                          );
                        } else {
                          return (
                            <Element
                              key={i}
                              field={field}
                              value={user[field.field_name]}
                              className="mt-3"
                            />
                          );
                        }
                      } else {
                        if (i == 0) {
                          return <Element key={i} field={field} />;
                        } else {
                          return (
                            <Element key={i} field={field} className="mt-3" />
                          );
                        }
                      }
                    })
                  : null}

                <GeneratePasswordResetLink />

                <CButtonGroup className="mt-3">
                  <CButton type="submit">Submit</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeleteUser()}
                  >
                    Delete
                  </CButton>
                </CButtonGroup>

                <CCard className="mt-5">
                  <CCardHeader>User Bookings</CCardHeader>
                  <CCardBody>
                    <UserBookingComponent />
                  </CCardBody>
                </CCard>
                <div className="mt-5">
                  <UserMembershipComponent />
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </>
      )
    );
  } else {
    return (
      !isLoading && (
        <>
          <Modal
            show={showModal}
            handleClose={hideModal}
            message={modalMessage}
            mode={modalMode}
          ></Modal>
          <CCard>
            <CCardBody>
              <CForm
                className="needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                {fields
                  ? fields.map((field, i) => {
                      if (i == 0) {
                        return <Element key={i} field={field} />;
                      } else {
                        return (
                          <Element key={i} field={field} className="mt-3" />
                        );
                      }
                    })
                  : null}
                <br />
                <div>
                  <CFormLabel htmlFor="validationCustom01">
                    <strong>Password (Randomly generated)</strong>
                  </CFormLabel>
                  <CFormInput
                    id="password"
                    value={password}
                    required={true}
                    name="password"
                    disabled={true}
                  />
                </div>
                <CButtonGroup className="mt-3">
                  <CButton type="submit">Submit</CButton>
                  <CButton
                    className="btn-danger ml-3"
                    onClick={() => confirmDeleteUser()}
                  >
                    Delete
                  </CButton>
                </CButtonGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </>
      )
    );
  }
}
