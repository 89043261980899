import {
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CContainer,
  CRow,
  CCol,
  CButton,
  CFormFeedback,
} from "@coreui/react";
import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../hooks/AuthContext";
import { useHistory } from "react-router";
import Modal from "../components/Modal";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const authContext = useContext(AuthContext);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmNewPasswordValid, setConfirmNewPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const [userToReset, setUserToReset] = useState(null);
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState(
    "Please confirm your password"
  );

  const updatePassword = (value) => {
    setNewPassword(value);
    if (!regex.test(value)) {
      setIsLoading(true);
      setNewPasswordValid(false);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setNewPasswordValid(true);
      setIsLoading(false);
    }
  };

  const updateConfirmNewPassword = (value) => {
    setConfirmNewPassword(value);
    if (newPassword != value) {
      setIsLoading(true);
      setConfirmNewPasswordValid(false);
      setConfirmPasswordErrorMsg("Passwords do not match");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setConfirmNewPasswordValid(true);
      setIsLoading(false);
    }
  };

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        if (modalMode == "Info") {
          logout();
        }
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/validate-reset/" +
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ]
      )
      .then((res) => {
        console.log(res.data);
        setUserToReset(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setModalMode("Error");
        displayModal(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  const logout = () => {
    authContext.logout();
    history.replace("/login");
  };

  const regex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
  );

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    setIsLoading(true);
    if (newPassword != confirmNewPassword) {
      alert("New passwords do not match");
      e.preventDefault();
      e.stopPropagation();
      setNewPasswordValid(false);
      setConfirmNewPasswordValid(false);
      setIsLoading(false);
    } else if (!regex.test(newPassword)) {
      setIsLoading(true);
      alert(regex.test(newPassword));
      e.preventDefault();
      e.stopPropagation();
      setNewPasswordValid(false);
      setConfirmNewPasswordValid(false);
      setIsLoading(false);
    } else {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        e.preventDefault();
        setNewPasswordValid(true);
        setConfirmNewPasswordValid(true);
      }
    }
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/reset-password/" +
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ],
        {
          newPassword,
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setModalMode("Info");
          displayModal("Successfully reset password. Please log in again");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setModalMode("Error");
        displayModal(err.response.data.message);
        setIsLoading(false);
      });
    setValidated(true);
  };

  const history = useHistory();

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          title={modalTitle}
          message={modalMessage}
          mode={modalMode}
        ></Modal>
        <CContainer>
          <CCard>
            <CCardBody>
              <CRow>
                <CCol lg={12}>
                  <CCardHeader>
                    Reset Password for{" "}
                    {userToReset != null
                      ? userToReset.firstName + " " + userToReset.lastName
                      : ""}
                  </CCardHeader>
                  <CCardBody>
                    <CForm
                      className="needs-validation"
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div>
                        <CFormLabel htmlFor="validationCustom01">
                          New Password
                        </CFormLabel>
                        <CFormInput
                          type="password"
                          name="newPassword"
                          required
                          className="form-control"
                          onChange={(e) => updatePassword(e.target.value)}
                          valid={newPasswordValid}
                          invalid={!newPasswordValid}
                        />
                        <CFormFeedback invalid>
                          Password must contain at least 1 uppercase, 1
                          lowercase, 1 number, 1 special character and min. 6
                          characters long
                        </CFormFeedback>
                      </div>
                      <div>
                        <CFormLabel htmlFor="validationCustom01">
                          Confirm New Password
                        </CFormLabel>
                        <CFormInput
                          type="password"
                          name="confirmNewPassword"
                          required
                          className="form-control"
                          onChange={(e) =>
                            updateConfirmNewPassword(e.target.value)
                          }
                          valid={confirmNewPasswordValid}
                          invalid={!confirmNewPasswordValid}
                        />
                        <CFormFeedback invalid>
                          {confirmPasswordErrorMsg}
                        </CFormFeedback>
                      </div>
                      <CButton type="submit" className="mt-3">
                        Submit
                      </CButton>
                      <br />
                    </CForm>
                  </CCardBody>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CContainer>
      </>
    )
  );
}
