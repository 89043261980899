import React, { useState, useEffect } from "react";
import CreateNewUserForm from "../components/CreateNewUserForm";
import { CCard, CCardHeader, CCardBody, CContainer, CCol } from "@coreui/react";

export default function GuestSignUp() {
  const [userDetails, setUserDetails] = useState(null);
  const updateUserDetails = (details) => {
    console.log(details);
    setUserDetails(details);
    localStorage.removeItem("userDetails");
    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        details,
      })
    );
  };

  return (
    <>
      <div className="d-flex bg-light border">
        <CCard style={{ width: "100%" }}>
          <CCardBody>
            <CContainer>
              <CCol>
                <CCardHeader>Create An Account</CCardHeader>
                <CreateNewUserForm userDetails={userDetails} />
              </CCol>
            </CContainer>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
}
