import React, { useState, useEffect, useContext } from "react";

import formJSON from "../formConfigs/CreateUserPublic.json";
import Element from "./Element";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CCol,
  CRow,
  CCardHeader,
  CFormFeedback,
  CFormSelect,
} from "@coreui/react";
import axios from "axios";
import { AuthContext } from "../hooks/AuthContext";
import { useForm } from "react-hook-form";
import Modal from "./Modal";
import { useHistory } from "react-router";

export default function CreateUser(props) {
  const [validated, setValidated] = useState(false);
  const [selectValue, setSelectValue] = useState("Please select a gender");
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmNewPasswordValid, setConfirmNewPasswordValid] = useState(false);
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState(
    "Please confirm your password"
  );
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  //Form Fields
  const [user, setUser] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const history = useHistory();

  const updatePassword = (value) => {
    setPassword(value);
    if (!regex.test(value)) {
      setIsLoading(true);
      setNewPasswordValid(false);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setNewPasswordValid(true);
      setIsLoading(false);
    }
  };

  const updateConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (password != value) {
      setIsLoading(true);
      setConfirmNewPasswordValid(false);
      setConfirmPasswordErrorMsg("Passwords do not match");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setConfirmNewPasswordValid(true);
      setIsLoading(false);
    }
  };

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        if (
          !props.hasOwnProperty("updateUserDetails") &&
          modalMode != "Error"
        ) {
          history.replace("/login");
        }
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  const regex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
  );

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      if (password != confirmPassword) {
        setIsLoading(true);
        alert("New passwords do not match");
        e.preventDefault();
        e.stopPropagation();
        setNewPasswordValid(false);
        setConfirmNewPasswordValid(false);
        setIsLoading(false);
      } else if (!regex.test(password)) {
        setIsLoading(true);
        e.preventDefault();
        e.stopPropagation();
        setNewPasswordValid(false);
        setConfirmNewPasswordValid(false);
        setIsLoading(false);
      } else {
        setNewPasswordValid(true);
        setConfirmNewPasswordValid(true);
        const data = new FormData(e.target);
        var object = {};
        object["password"] = password;
        data.forEach(function (value, key) {
          if (key.toLowerCase().includes("date")) {
            object[key] = new Date(value);
          } else {
            object[key] = value;
          }
        });
        if (props.hasOwnProperty("updateUserDetails")) {
          props.updateUserDetails(object);
          props.nextStep();
        } else {
          object["email"] = object["email"].toLowerCase();
          object["accType"] = "Guest";
          object["isActive"] = true;
          axios
            .post(process.env.REACT_APP_API_URL + "/register", object)
            .then((res) => {
              console.log(res);
              setModalMode("Info");
              if (res.status == 200) {
                displayModal(
                  "Successfully created account. Please await SISA Admin's approval before logging in"
                );
              }
            })
            .catch((err) => {
              setModalTitle("Error creating new account");
              setModalMode("Error");
              displayModal(err.response.data.message);
            });
        }
      }
    }
    setValidated(true);
  };

  const [elements, setElements] = useState(null);
  useEffect(() => {
    setElements(formJSON[0]);
    setValidated(true);
    if (props.hasOwnProperty("updateTitle")) {
      props.updateTitle("Create User");
    }
    setIsLoading(false);
  }, []);

  const { fields, page_label } = elements ?? {};

  /** Listener for all form fields **/
  function handleEventNameChange(e) {
    this.setState({ email: e.target.value });
  }

  const SubmitButtons = () => {
    if (props.hasOwnProperty("updateUserDetails")) {
      return (
        <>
          <CButton className="mt-3" onClick={() => props.prevStep()}>
            Prev
          </CButton>
          <CButton className="mt-3" type="submit">
            Next
          </CButton>
        </>
      );
    }
    return (
      <>
        <CButtonGroup className="mt-3">
          <CButton type="submit">Submit</CButton>
        </CButtonGroup>
      </>
    );
  };

  const ActualForm = () => {
    if (props.userDetails != null) {
      return fields.map((field, i) => {
        if (i == 0) {
          return (
            <Element
              key={i}
              field={field}
              value={props.userDetails[field.field_name]}
            />
          );
        } else {
          return (
            <Element
              key={i}
              field={field}
              className="mt-3"
              field={field}
              value={props.userDetails[field.field_name]}
            />
          );
        }
      });
    }
    return fields
      ? fields.map((field, i) => {
          if (i == 0) {
            return <Element key={i} field={field} />;
          } else {
            return <Element key={i} field={field} className="mt-3" />;
          }
        })
      : null;
  };

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          title={modalTitle}
          message={modalMessage}
          mode={modalMode}
        ></Modal>
        <CCard>
          <CCardBody>
            <CForm
              className="needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CFormLabel htmlFor="validationCustom01">Email</CFormLabel>
              <CFormInput
                type="email"
                name="email"
                required
                className="form-control"
              />
              {!props.hasOwnProperty("updateUserDetails") ? (
                <>
                  <CFormLabel htmlFor="validationCustom01">
                    First Name
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    name="firstName"
                    required
                    className="form-control"
                  />
                  <CFormLabel htmlFor="validationCustom01">
                    Last Name
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    name="lastName"
                    required
                    className="form-control"
                  />
                </>
              ) : null}
              <div>
                <CFormLabel htmlFor="validationCustom01">
                  New Password
                </CFormLabel>
                <CFormInput
                  type="password"
                  name="newPassword"
                  required
                  className="form-control"
                  onChange={(e) => updatePassword(e.target.value)}
                  valid={newPasswordValid}
                  invalid={!newPasswordValid}
                  pattern={
                    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
                  }
                />
                <CFormFeedback invalid>
                  Password must contain at least 1 uppercase, 1 lowercase, 1
                  number, 1 special character and min. 6 characters long
                </CFormFeedback>
              </div>
              <div>
                <CFormLabel htmlFor="validationCustom01">
                  Confirm Password
                </CFormLabel>
                <CFormInput
                  type="password"
                  name="confirmNewPassword"
                  required
                  className="form-control"
                  onChange={(e) => updateConfirmPassword(e.target.value)}
                  valid={confirmNewPasswordValid}
                  invalid={!confirmNewPasswordValid}
                />
                <CFormFeedback invalid>{confirmPasswordErrorMsg}</CFormFeedback>
              </div>
              <SubmitButtons />
            </CForm>
          </CCardBody>
        </CCard>
      </>
    )
  );
}
