import {
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CButton,
  CCardFooter,
  CButtonGroup,
} from "@coreui/react";
import React, { useEffect, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarkerAlt,
  faDollarSign,
  faArrowDown,
  faArrowUp,
  faTag,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../hooks/CartContext";
import CartItem from "../components/CartItem";
import axios from "axios";
import Modal from "../components/Modal";
import { useHistory, useLocation } from "react-router";
import querystring from "querystring";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from "../hooks/AuthContext";
import "../assets/css/style.css";

export default function Cart(props) {
  const { cart } = useContext(CartContext);
  const { user } = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [redirectURL, setRedirectURL] = useState("");
  let status;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    if (props.hasOwnProperty("updateTitle")) {
      props.updateTitle("Cart");
    }

    getTotalPrice();
    const script = document.createElement("script");
    script.src = "https://sandbox.hit-pay.com/hitpay.js";
    script.async = true;

    document.body.appendChild(script);
    /*if (
      query.get("reference") != null &&
      query.get("status") === "completed" &&
      !props.hasOwnProperty("nextStep")
    ) {
      processBooking(query.get("reference"), "PayNow", "hitpay");
    }*/
    setIsLoading(false);
  }, []);

  let t = null;
  let maxT = null;

  const startPolling = async () => {
    let result = await axios.get(
      process.env.REACT_APP_API_URL + "/payments/status/hitpay/" + paymentId
    );
    if (result.data.status == "pending") {
      status = result.data.status;
      clearInterval(t);
      t = null;
      t = setInterval(startPolling, 2000);
      if (maxT == null) {
        maxT = setTimeout(() => {
          cancel();
        }, 180000);
      }
    } else if (result.data.status == "completed") {
      emptyCart();
      clearInterval(t);
      clearTimeout(maxT);
      history.replace(redirectURL);
      return;
    } else {
      emptyCart();
      clearInterval(t);
      clearTimeout(maxT);
      history.replace(redirectURL);
      return;
    }
  };

  function processBooking(paymentRef, paymentMethod, paymentProvider) {
    let events = [];
    let additionalFields = new Map();
    cart.forEach((item) => {
      if (item.hasOwnProperty("event")) {
        events.push(item.event._id);
        additionalFields[item.event._id] = item.additionalFields;
      }
    });
    if (events.length > 0) {
      let object = {
        events: events,
        paymentRef: paymentRef,
        paymentMethod: paymentMethod,
        paymentProvider: paymentProvider,
        price: getTotalPrice(),
        additionalFields,
      };
      axios
        .post(process.env.REACT_APP_API_URL + "/payments/checkout", object)
        .then((res) => {
          if (paymentProvider == "PayPal") {
            setLoadingVisible(true);
            emptyCart();
            history.replace("/bookings");
            return;
          } else {
            setLoadingVisible(true);
            setPaymentId(res.data.paymentReqId);
            window.open(res.data.paymentCheckoutURL, "_blank");
            setRedirectURL("/bookings");
            startPolling();
          }
        })
        .catch((err) => {});

      return;
    }

    cart.forEach((item) => {
      if (item.hasOwnProperty("membershipId")) {
        let object = {
          membershipId: item.membershipId,
          membershipDetails: item.membershipDetails,
          userId: user._id,
          paymentRef: paymentRef,
          paymentMethod: paymentMethod,
          paymentProvider: paymentProvider,
          price: getTotalPrice(),
        };
        axios
          .post(process.env.REACT_APP_API_URL + "/payments/checkout", object)
          .then((res) => {
            console.log(res.data);
            if (paymentProvider == "PayPal") {
              setLoadingVisible(true);
              emptyCart();
              history.replace("/profile");
              return;
            } else {
              setLoadingVisible(true);
              setPaymentId(res.data.paymentReqId);
              window.open(res.data.paymentCheckoutURL, "_blank");
              setRedirectURL("/profile");
              startPolling();
            }
          })
          .catch((err) => {});
      }
    });
  }

  function emptyCart() {
    cartContext.emptyCart();
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  function getTotalPrice() {
    let totalPrice = 0;
    cart.forEach((item) => {
      totalPrice += parseFloat(item.price);
      console.log(item);
    });
    console.log(totalPrice);
    return totalPrice;
  }

  function checkout() {
    processBooking("", "PayNow", "HitPay");
  }

  useEffect(() => {
    if (paymentId != undefined && paymentId != "") {
      startPolling();
    }
  }, [paymentId]);

  const cancel = () => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/payments/cancel/hitpay/" + paymentId
      )
      .then((res) => {
        if (res.status == 200) {
          setLoadingVisible(false);
          setIsLoading(false);
          clearInterval(t);
          clearTimeout(maxT);
          maxT = null;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingVisible(false);
        setIsLoading(false);
      });
  };

  let orderId;
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: getTotalPrice(),
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    console.log(data);
    console.log(actions);
    if (props.hasOwnProperty("nextStep")) {
    } else {
      processBooking(data.orderID, "Credit Card", "PayPal");
    }
    return actions.order.capture();
  };

  const SubmitButtons = () => {
    if (props.hasOwnProperty("prevStep")) {
      return (
        <>
          <CButton className="mt-3" onClick={() => props.prevStep()}>
            Prev
          </CButton>
        </>
      );
    }
    return null;
  };

  const LoadingOverlay = () => {
    return (
      !isLoading &&
      loadingVisible && (
        <div className="cart-loading-overlay">
          Waiting for payment....
          <br />
          Please complete payment within 3 minutes.
          <a onClick={() => cancel()}>Click here if you wish to cancel</a>
        </div>
      )
    );
  };

  function CheckoutButtons() {
    if (cart.length > 0) {
      var options = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "SGD",
      };
      return (
        <>
          <CButtonGroup className="flex-column">
            <CButton
              className="btn-primary mt-3 mr-3"
              onClick={() => checkout()}
            >
              PayNow
            </CButton>
            <CButton className="btn-danger mt-3" onClick={() => emptyCart()}>
              <FontAwesomeIcon className="mr-3" icon={faTrash} /> Empty Cart
            </CButton>
            <div className="mt-3">
              <PayPalScriptProvider options={options}>
                <PayPalButtons
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                />
              </PayPalScriptProvider>
            </div>
          </CButtonGroup>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          message={modalMessage}
          mode={modalMode}
        ></Modal>
        <LoadingOverlay />
        <CCard>
          <CCardBody>
            <CRow>
              <CCol lg={12}>
                <CCard>
                  <CCardHeader className="cart-header">
                    <CRow>
                      <CCol lg={3}>
                        <h6 className="mb-0">
                          <strong>Item</strong>
                        </h6>
                      </CCol>
                      <CCol lg={3}>
                        <h6 className="mb-0">
                          <strong>Description</strong>
                        </h6>
                      </CCol>
                      <CCol lg={3}>
                        <h6 className="mb-0">
                          <strong>Price</strong>
                        </h6>
                      </CCol>
                    </CRow>
                  </CCardHeader>
                  {cart.map((item) => {
                    return (
                      <CCardBody className="cart-row">
                        <CartItem cartItem={item} />
                      </CCardBody>
                    );
                  })}
                  <CCardFooter className="cart-footer">
                    <CRow>
                      <CCol lg={4}>
                        <h6 className="mb-0">
                          <strong>Total Price</strong>
                        </h6>
                      </CCol>
                      <CCol lg={4}>
                        <h6 className="mb-0">
                          <strong>${getTotalPrice().toFixed(2)}</strong>
                        </h6>
                      </CCol>
                    </CRow>
                  </CCardFooter>
                </CCard>
                <CheckoutButtons />
              </CCol>
            </CRow>
            <SubmitButtons />
          </CCardBody>
        </CCard>
      </>
    )
  );
}
