export default [
	{
		_tag: "CSidebarNavItem",
		name: "Dashboard",
		to: "/admin/dashboard",
		/*icon: 'cil-speedometer',
    badge: {
      color: 'info',
      text: 'NEW',
    }*/
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["About SISA"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "General Info & Constitution",
		to: "/admin/about-sisa/general-info",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Organisation",
		to: "/admin/about-sisa/organisation",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Policies",
		to: "/admin/about-sisa/policies",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Communications",
		to: "/admin/about-sisa/communications",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "AGMs",
		to: "/admin/about-sisa/agms",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Anti Doping"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Useful Links",
		to: "/admin/anti-doping/useful-links",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavDivider",
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Figure Skating"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Team",
		to: "/admin/figure-skating/team",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Rules",
		to: "/base/forms",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "International Assignments",
		to: "/admin/figure-skating/international-assignments",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "SISA Tests",
		to: "/admin/figure-skating/sisa-tests",
		/*icon: 'cil-drop',*/
	},
	/*{
    _tag: 'CSidebarNavDropdown',
    name: 'SISA Tests',
    route: '/pages',
     /*icon: 'cil-drop',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Login',
        to: '/login',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Register',
        to: '/register',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Error 404',
        to: '/404',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Error 500',
        to: '/500',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Disabled',
     /*icon: 'cil-drop',
    badge: {
      color: 'secondary',
      text: 'NEW',
    },
    addLinkClass: 'c-disabled',
    'disabled': true
  },*/
	{
		_tag: "CSidebarNavDivider",
		className: "m-2",
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Short Track"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Team",
		to: "/admin/short-track/team",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Rules",
		to: "/base/forms",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "International Assignments",
		to: "/admin/short-track/international-assignments",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Time Trials",
		to: "/admin/short-track/time-trials",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavDivider",
		className: "m-2",
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Results and Rankings"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Results",
		to: "/admin/results",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Rankings",
		to: "/admin/rankings",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "National Records",
		to: "/admin/national-records",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["News"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Announcements",
		to: "/admin/news/announcements",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Events Calendar",
		to: "/charts",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Capability Development"],
	},
	{
		_tag: "CSidebarNavItem",

		name: "Coaching",
		to: "/admin/capability-development/coaching",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Officiating",
		to: "/admin/capability-development/officiating",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Volunteering",
		to: "/admin/capability-development/volunteering",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Contact"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Form Enquiries",
		to: "/admin/contact/enquiries",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Media"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Photos",
		to: "/admin/media/photos",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Videos",
		to: "/admin/media/videos",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavTitle",
		_children: ["Misc"],
	},
	{
		_tag: "CSidebarNavItem",
		name: "Learn To Skate",
		to: "/admin/media/videos",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Join SISA",
		to: "/admin/join-sisa",
		/*icon: 'cil-drop',*/
	},
	{
		_tag: "CSidebarNavItem",
		name: "Support Our Athletes",
		to: "/admin/media/videos",
		/*icon: 'cil-drop',*/
	},
];
