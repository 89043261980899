import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CFormLabel,
  CContainer,
  CCol,
  CRow,
  CCardHeader,
  CBadge,
} from "@coreui/react";
import "../../assets/css/events.css";
import axios from "axios";
import { AuthContext } from "../../hooks/AuthContext";
import Modal from "../../components/Modal";
import { useHistory } from "react-router";
import moment from "moment";

export default function EventCategories(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const [modalLink, setModalLink] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [categories, setCategories] = useState([]);

  //For Modal Functions
  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        history.push("/events/categories");
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    document.title = "Event Categories";
    props.updateTitle("Event Categories");
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/events/categories")
      .then((res) => {
        setCategories(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        displayModal(err.response.data.message);
        setIsLoading(false);
      });
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => (row.name != null ? row.name : ""),
      sortable: true,
    },
    {
      name: "Alias",
      selector: (row) => row.alias,
      sortable: true,
    },
    {
      name: "Fields",
      selector: (row) => row.fields,
      sortable: true,
      cell: (row) => {
        return row.fields.map((val) => {
          console.log(val);
          return <div key={val._id}>{val.field_name + ", "}</div>;
        });
      },
    },
  ];

  const onRowClicked = (e) => {
    history.push("/events/categories/edit/" + e._id);
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log(selectedRows);
  };

  return (
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={hideModal}
          message={modalMessage}
          mode={modalMode}
          link={modalLink}
        ></Modal>
        <CCard>
          <CCardBody>
            <CCard>
              <CCardHeader>
                <CButton
                  className="mr-2"
                  onClick={() => history.push("/events/categories/create")}
                >
                  Add Category
                </CButton>
              </CCardHeader>
              <CCardBody>
                <DataTable
                  columns={columns}
                  data={categories}
                  pagination
                  onSelectedRowsChange={handleChange}
                  onRowClicked={onRowClicked}
                />
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </>
    )
  );
}
