import React, { useContext, useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CRow,
  CCol,
  CCardHeader,
  CButton,
  CButtonGroup,
  CLink,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarkerAlt,
  faDollarSign,
  faArrowDown,
  faArrowUp,
  faHourglass,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import moment from "moment";
import { AuthContext } from "../hooks/AuthContext";
import Cart from "./Cart";
import { CartContext } from "../hooks/CartContext";
import EventBookingItem from "../components/EventBookingItem";
import BookingItem from "../components/BookingItem";
import Events from "./Events";
import Modal from "../components/Modal";
import { useHistory } from "react-router";
import AdditionalUserInput from "../components/AdditionalUserInput";

export default function EventDetail(props) {
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInCart, setIsInCart] = useState(false);
  const { cart } = useContext(CartContext);
  const { user } = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const [hasBooked, setHasBooked] = useState(false);
  const [membership, setMembership] = useState(false);

  //Modal
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMode, setModalMode] = useState("Info");
  const [modalTitle, setModalTitle] = useState("");
  const [modalLink, setModalLink] = useState("");

  //Additional User Input Modal
  const [showAdditionalUserInputModal, setShowAdditionalUserInputModal] =
    useState(false);
  const [additionaUserInputModalMessage, setAdditionaUserInputModalMessage] =
    useState(false);
  const [additionalUserInputModalMode, setAdditionalUserInputModalMode] =
    useState("Add To Cart");
  const [additionalUserInputModalTitle, setAdditionalUserInputModalTitle] =
    useState("");
  const [additionalUserInputModalLink, setAdditionalUserInputModalLink] =
    useState("");
  const [additionalUserInputModalFields, setAdditionalUserInputModalFields] =
    useState([]);

  function additionalInputListener(data) {
    console.log("Got data: " + Object.keys(data));
    console.log(data["File Upload"]);
    cartContext.addToCart({
      item: event.eventName,
      description: event.startDate + " - " + event.endDate,
      price: event.price,
      event: event,
      additionalFields: data,
    });
  }

  function displayModal(value) {
    setShowModal(true);
    setModalMessage(value);
  }

  function hideModal(value) {
    setShowModal(false);
    switch (value) {
      case "Close":
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  function hideAdditionalUserInputModal(value) {
    setShowAdditionalUserInputModal(false);
    switch (value) {
      case "Confirm and Add To Cart":
        //Add to cart
        break;
      case "Yes":
        break;
      case "No":
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    let event_;
    setIsLoading(true);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/events/" +
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ]
      )
      .then((event) => {
        event_ = event.data;
        axios
          .get(
            process.env.REACT_APP_API_URL +
              "/events/" +
              window.location.href.split("/")[
                window.location.href.split("/").length - 1
              ] +
              "/bookings"
          )
          .then((bookings) => {
            event_.bookings = bookings.data;
            event_.bookings.forEach((booking) => {
              if (booking.user._id == user._id) {
                setHasBooked(true);
              }
            });
            setEvent(event_);
            cart.map((cartItem) => {
              if (cartItem.event._id == event_._id) {
                setIsInCart(true);
              }
            });
            props.updateTitle(event_.eventName);
            if (
              event_.eventCategories != undefined &&
              event_.eventCategories.length > 0 &&
              event_.eventCategories[0].fields.length > 0
            ) {
              setAdditionalUserInputModalFields(
                event_.eventCategories[0].fields
              );
            }
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  "/users/" +
                  user._id +
                  "/membership"
              )
              .then((res) => {
                if (res.data.message == "This user has no active membership") {
                  setMembership(false);
                } else {
                  setMembership(true);
                }
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
              });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }, []);

  const displayAdditionalInputModal = (fields) => {
    setShowAdditionalUserInputModal(true);
  };

  function addToCart() {
    //Check if there are any categories and if so,
    //If there are any additional fields that require user input before adding to cart
    if (
      event.eventCategories.length > 0 &&
      event.eventCategories[0].fields.length > 0
    ) {
      displayAdditionalInputModal(event.eventCategories[0].fields);
    } else {
      cartContext.addToCart({
        item: event.eventName,
        description: event.startDate + " - " + event.endDate,
        price: event.price,
        event: event,
      });
      setIsInCart(true);
    }
  }

  function removeFromCart() {
    cartContext.removeFromCart({
      item: event.eventName,
      description: event.startDate + " - " + event.endDate,
      price: event.price,
      event: event,
    });
    setIsInCart(false);
  }

  function exportToCsv() {
    axios
      .get(
        process.env.REACT_APP_API_URL + "/events/" + event._id + "/downloadCSV"
      )
      .then((res) => {
        setModalMode("Link");
        setModalLink(res.data.link);
        displayModal("Download at: ");
      })
      .catch((err) => {
        console.log(err);
        setModalMode("Error");
        displayModal(err.message);
      });
  }

  useEffect(() => {}, [cart]);

  function CButtonGroup_() {
    if (user && user.accType != "Admin") {
      if (!membership && event.eventAccess == "Member") {
        return <>Please apply for a membership to book this event</>;
      } else if (isInCart) {
        return (
          !isLoading && (
            <CButtonGroup className="mt-3">
              <CButton className="btn-danger" onClick={removeFromCart}>
                Remove From Cart
              </CButton>
            </CButtonGroup>
          )
        );
      } else if (hasBooked) {
        return (
          <>
            <br />
            <strong>You are already booked into this event</strong>
            <CCard className="mt-3 mb-3">
              <CCardBody>
                <CLink href="/bookings" className="booking-details-toggle">
                  <CRow className="booking-item-name">
                    <CCol lg={9} className="flex-horizontal-center">
                      <h6 className="mb-0">
                        <a
                          href={
                            "/bookings/" +
                            event.bookings.find(
                              (element) => element.user._id == user._id
                            )._id
                          }
                        >
                          <strong>Booking ID:</strong>{" "}
                          {
                            event.bookings.find(
                              (element) => element.user._id == user._id
                            )._id
                          }{" "}
                        </a>
                        <br />
                        <strong>Created On:</strong>{" "}
                        {moment(
                          event.bookings.find(
                            (element) => element.user._id == user._id
                          ).createdAt
                        ).format("DD/MM/YYYY hh:mm:ss a")}
                      </h6>
                    </CCol>
                  </CRow>
                </CLink>
              </CCardBody>
            </CCard>
          </>
        );
      } else if (event.bookings.length == event.capacity) {
        return (
          !isLoading && (
            <CButtonGroup className="mt-3">
              <CButton className="btn-danger" disabled>
                Event Full
              </CButton>
            </CButtonGroup>
          )
        );
      } else {
        if (!membership && event.eventAccess == "Member") {
          return <>Please apply for a membership to book this event</>;
        }
        if (
          event.isCutOffDateEnabled != undefined &&
          event.isCutOffDateEnabled == "Enabled"
        ) {
          var currentDate = new Date();
          var cutOffDate = new Date(event.cutOffDate);
          if (currentDate.getTime() < cutOffDate.getTime()) {
            return (
              !isLoading && (
                <CButtonGroup className="mt-3">
                  <CButton className="btn-custom" onClick={addToCart}>
                    Add To Cart
                  </CButton>
                </CButtonGroup>
              )
            );
          } else {
            return (
              !isLoading && <div>Sorry, this event's deadline has passed</div>
            );
          }
        }
        return (
          !isLoading && (
            <CButtonGroup className="mt-3">
              <CButton className="btn-custom" onClick={addToCart}>
                Add To Cart
              </CButton>
            </CButtonGroup>
          )
        );
      }
    }
    return !isLoading && null;
  }

  function MembersBooked() {
    if (user.accType == "Admin" && event.bookings.length > 0) {
      return (
        <CCard className="mt-3">
          <CCardHeader className="event-card-header-row">
            <h6>Members Booked</h6>
            <CButton onClick={() => exportToCsv()}>Export to CSV</CButton>
          </CCardHeader>
          <CCardBody>
            {event.bookings.map((booking) => {
              return <EventBookingItem booking={booking} />;
            })}
          </CCardBody>
        </CCard>
      );
    }
    return null;
  }

  return (
    cart &&
    event &&
    !isLoading && (
      <>
        <Modal
          show={showModal}
          handleClose={() => hideModal(modalMode)}
          title={modalTitle}
          message={modalMessage}
          link={modalLink}
          mode={modalMode}
        ></Modal>
        <AdditionalUserInput
          show={showAdditionalUserInputModal}
          handleClose={() => hideAdditionalUserInputModal(modalMode)}
          fields={additionalUserInputModalFields}
          mode={additionalUserInputModalMode}
          title={"Additional Input Required"}
          event={event}
          submitListener={additionalInputListener}
        />
        <CCard>
          <CCardBody>
            <CRow>
              <CCol lg={8}>
                <CCard>
                  <CCardHeader>
                    <h6>Event Details</h6>
                  </CCardHeader>
                  <CCardBody>
                    <CRow className="mt-2 mb-2">
                      <CCol>
                        <FontAwesomeIcon className="mr-3" icon={faTag} />
                        {event.eventType}
                      </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-2">
                      <CCol>
                        <FontAwesomeIcon icon={faHourglass} className="mr-3" />
                        Registration Cut Off Date:{" "}
                        {event.isCutOffDateEnabled != undefined &&
                        event.isCutOffDateEnabled == "Enabled" &&
                        event.cutOffDate != undefined
                          ? moment(event.cutOffDate).format(
                              "DD/MM/YYYY hh:mm:ss a"
                            )
                          : "No Cut Off Date"}
                      </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-2">
                      <CCol>
                        <FontAwesomeIcon className="mr-3" icon={faCalendar} />
                        {moment(event.startDate).format(
                          "DD/MM/YYYY hh:mm:ss a"
                        )}{" "}
                        -{" "}
                        {moment(event.endDate).format("DD/MM/YYYY hh:mm:ss a")}
                      </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-2">
                      <CCol>
                        <FontAwesomeIcon
                          className="mr-3"
                          icon={faMapMarkerAlt}
                        />
                        {event.address}
                      </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-2">
                      <CCol>
                        <FontAwesomeIcon className="mr-3" icon={faDollarSign} />
                        ${event.price}
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol lg={4}>
                <CCard>
                  <CCardHeader>
                    <h6>Other Info</h6>
                  </CCardHeader>
                  <CCardBody>
                    Capacity: {event.capacity}
                    <br />
                    Current Bookings: {event.bookings.length}
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg={12}>
                <MembersBooked />
              </CCol>
            </CRow>
            <CButtonGroup_ />
          </CCardBody>
        </CCard>
      </>
    )
  );
}
