import React from "react";
import { CCard, CCardBody, CRow, CCol } from "@coreui/react";
import moment from "moment";

export default function EventBookingItem(props) {
  const booking = props.booking;

  return (
    <>
      <CCard className="mt-3 mb-3">
        <CCardBody>
          <CRow className="booking-item-name">
            <CCol lg={9} className="flex-horizontal-center">
              <h6 className="mb-0">
                <strong>Name:</strong> {booking.user.firstName}{" "}
                {booking.user.lastName} <br />
                <strong>Booking ID:</strong> {booking._id} <br />
                <strong>Created On:</strong>{" "}
                {moment(booking.createdAt).format("DD/MM/YYYY hh:mm:ss a")}
                <br />
                <strong>Invoice No:</strong> {booking.invoiceNo}
                <br />
                <strong>Invoice Link:</strong>{" "}
                <a href={booking.invoiceLink} target="_blank">
                  {booking.invoiceLink}
                </a>
              </h6>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
}
